import React from 'react'

const Aboutus = () => {
  return (
    <>
     {/* <!--About us--> */}
        <section class="aboutsec-2 secpaddbig">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-sm-6">
                        <div class="abotimglft">
                            <img src="./assets/images/resources/about2.jpg" class="img-responsive" alt=''/>
                        </div>
                    </div>
                    <div class="col-md-8 col-sm-6">
                        <div class="abotinforgt">
                            <div class="fh-section-title clearfix  text-left version-dark paddbtm30">
                                <h2>Who We are</h2>
                            </div>
                            <p>We strongly believe that pursuing all of these goals is in our interest and in the interest of all of our stakeholders are us customers, employees, investors and the planet as a whole. We add value to cargo people’s interaction with us, with excellent services or products.</p>
                            <p>Engaging our employees and nurturinvestment on the stock holds market we show concern , by engaging our employees and nurturinvestment on the stock holds market we show concern.</p>
                            <img class="paddtop20" src="./assets/images/icon/signature-1.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!--About us end--> */}
    </>
  )
}

export default Aboutus