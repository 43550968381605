import React from 'react'
import img1 from "./../assets/img1.webp"
import img2 from "./../assets/img2.webp"
import img3 from "./../assets/img3.webp"
import img4 from "./../assets/img4.webp"
import slide1 from "./../assets/slide1.webp"
import slide2 from "./../assets/slide2.webp"
import slide3 from "./../assets/slide3.webp"
import slide4 from "./../assets/slide4.webp"
import slide5 from "./../assets/slide5.webp"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';


import flower from "./../assets/flower.webp"

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard,Autoplay } from 'swiper/modules';
import TransportBannner from '../Component/Transport360/TransportBannner'

const Transport360 = () => {
  return (
    <div>
<TransportBannner backimg={flower} header={"Welcome to Transporte360: EverBlue!"} para={"Here you will find a complete directory with resources, tools, news and expert opinions on sustainability, with a special focus on reducing and offsetting the carbon footprint for both companies, especially focused on transportation and logistics."}/>
<div className="container-fluid ">
    <div className="row gy-0">
        <div className="col-md-4 pt-5" >
<img  src={img1} alt="" srcset="" style={{
    width: "100%",
   height: "70%",   
    objectFit: "cover",
   marginTop:"12rem" 
}}/>
        </div>
        <div className="col-md-8 pt-5">
<img  src={img2} alt="" srcset="" style={{
    width: "100%",
   height: "70%", 
   marginTop:"12rem " , 
   objectFit: "cover"
}} />
        </div>
        <div className="col-md-8 pb-5">
<img  src={img3} alt="" srcset="" style={{
    width: "100%",
   height: "70%",    objectFit: "cover"
}} />
        </div>
        <div className="col-md-4 pb-5">
<img  src={img4} alt="" srcset="" style={{
    width: "100%",
    height: "70%",
    objectFit: "cover"
}} />
        </div>
    </div>
</div>
<div className="container py-5">
    <section className='slidertxt'>
        <div className="row">
            <div className="col-md-12">
                <h2 className="text-center" style={{
                    color:"#2489F3",
                    fontSize:"38px",

                }}>
                SDGS ON WHICH WE IMPACT

                </h2>
                <p className='text-center' style={{
                 
                    fontSize:"22px",

                }}> The Sustainable Development Goals (SDGs) are a set of 17 goals set by the United Nations for 2030, designed to protect the environment and promote sustainability. 


</p>
            </div>
        </div>
<Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}        
          loop={true}
        keyboard={true}
        modules={[Navigation,Autoplay, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
        slidesPerView={1}
      >
        <SwiperSlide>
            <img src={slide1} alt="" srcset="" />
        </SwiperSlide>
        <SwiperSlide>          
            <div className="container">
                <img src={slide1} alt="" srcset="" />
                </div>  
</SwiperSlide>
        <SwiperSlide>    
              <div className="container">  
            <img src={slide2} alt="" srcset="" />
            </div> 

</SwiperSlide>
        <SwiperSlide>    
               <div className="container">  
           <img src={slide3} alt="" srcset="" />
           </div>
</SwiperSlide>
        <SwiperSlide>     
              <div className="container">    
               <img src={slide4} alt="" srcset="" />
               </div>
</SwiperSlide>
        <SwiperSlide>      
             <div className="container">        
              <img src={slide5} alt="" srcset="" />
              </div>
</SwiperSlide>
    
      </Swiper>
      </section>
    </div>
    
    </div>
  
  )
}

export default Transport360
