import React from 'react'
import Pagetitle from '../Component/Pagetitle'
import equip1 from "../assets/equip1.webp"
import equip2 from "../assets/equip2.webp"
const Equipment = () => {
  return (
    <div>
            <Pagetitle title="Equipment"/>

      <div className="container">
      <div className="row py-5">
        <div className="col-md-12">
            <h1 className='text-center py-5' style={{
                fontSize:"48px",
                fontWeight:"300",
                color:"rgb(9, 122, 241)"
            }}>Equipment</h1>
        </div>
        <div className="col-md-6 ">
<div className="row">
    <div className="col-md-12">
        <img src={equip1} alt="" srcset="" />
    </div>
    <div className="col-md-12">
        <div className="box bg-blue" style={{
            backgroundColor:"#097AF1",
            padding:"20px",
            color:"white"
        }}>
<h1 style={{
    fontWeight:"300"
}}>Sales Responsible</h1>
<p style={{
    fontSize:"16px"
}}>There are no hard sales here.</p>
        </div>
    </div>
</div>
        </div>
        <div className="col-md-6">
<div className="row">
    <div className="col-md-12">
        <img src={equip2} alt="" srcset="" />
    </div>
    <div className="col-md-12">
        <div className="box bg-blue" style={{
            backgroundColor:"#097AF1",
            padding:"20px",
            color:"white"
        }}>
<h1 style={{
    fontWeight:"300"
}}>Customer Service Assistant
</h1>
<p style={{
    fontSize:"16px"
}}>Always at your service..</p>
        </div>
    </div>
</div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Equipment
