import React from 'react'
import Slider from '../Component/Slider'
import Welcome from '../Component/Welcome'
// import Services from '../Component/Services/Services'
// import Whychoose from '../Component/Whychoose'

const Home = () => {
  return (
    <>
    <Slider />
    <Welcome/>
    {/* <Services/> */}
    {/* <Whychoose/> */}
    </>
  )
}

export default Home