import React from "react";

const Section3 = () => {
  return (
    <>
      {/* <!-- featured sec --> */}
      <section class="features-1 bluebg">
        <div class="row">
          <div class="col-md-4 col-sm-6">
            <div class="frs1box">
              <div class="fh-icon-box  style-3 version-dark hide-button icon-left">
                <span class="fh-icon">
                  <i class="flaticon-internet"></i>
                </span>
                <h4 class="box-title">
                  <span>Fast worldwide delivery</span>
                </h4>
                <div class="desc">
                  <p>
                    There are many variations of passages of available, but the
                    majority have suffered alteration in some form, by or
                    randomised slightly believable.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="frs1box">
              <div class="fh-icon-box  style-3 version-dark hide-button icon-left">
                <span class="fh-icon">
                  <i class="flaticon-shield"></i>
                </span>
                <h4 class="box-title">
                  <span>Safe and Secure Services</span>
                </h4>
                <div class="desc">
                  <p>
                    There are many variations of passages of available, but the
                    majority have suffered alteration in some form, by or
                    randomised slightly believable.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="frs1box">
              <div class="fh-icon-box  style-3 version-dark hide-button icon-left">
                <span class="fh-icon">
                  <i class="flaticon-technology"></i>
                </span>
                <h4 class="box-title">
                  <span>24/7 customer support</span>
                </h4>
                <div class="desc">
                  <p>
                    There are many variations of passages of available, but the
                    majority have suffered alteration in some form, by or
                    randomised slightly believable.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- featured sec end --> */}
    </>
  );
};

export default Section3;
