import React from 'react'

const Features = () => {
  return (
    <>
    
    {/* <!-- Feature sec --> */}
        <section class="features-3 bluebg">
            <div class="container">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="fh-feature-box "><span class="chars">M</span>
                            <h4 class="box-title">Our Mission</h4>
                            <div class="desc">We meet our customers’ demands for a personal &amp; profesional service by offering innovative supply chain solutions.</div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="fh-feature-box "><span class="chars">V</span>
                            <h4 class="box-title">Our Vision</h4>
                            <div class="desc">We proactively and constantly look for new possibilities. Therefore, an important part of our vision is to attract &amp; retain.</div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="fh-feature-box "><span class="chars">C</span>
                            <h4 class="box-title">Core Values</h4>
                            <div class="desc">Procedures, values and attitudes are crucial to our reputation – not to mention the success we enjoy.</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Feature sec end --> */}
    </>
  )
}

export default Features