/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



// import required modules
import { Navigation, Pagination,Autoplay, Mousewheel, Keyboard } from 'swiper/modules';
const DynamicSlider = (props) => {
  const {slide1,slide2,slide3,slide4,slide5,link1,link2,link3,link4,link5,head1,para1,head2,para2,head3,para3,head4,para4,head5,para5,btn,percentage}=props
  return (
    <div className='container'>

       <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}        
          loop={true}
        keyboard={true}
        modules={[Navigation,Autoplay, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
        slidesPerView={1}
      >

        <SwiperSlide>
          <div classname="col-md-12">
          <div className=" mainwrap"style={{
            paddingBottom:`${percentage}`
          }}>

            <div className='wrap'>
            <a href={link1} target='_blank' >
              <div className='img'>

            <img  src={slide1} alt="" width="42%" srcset=""/>
       
            </div>
            </a>
            </div>
            </div>
            <div className="content">
            <h3 >{head1}
</h3>
<p>{para1}

</p>
{btn &&
  <button type="button" class="btn btn-outline-primary">see more article</button>
  }
    
</div>

</div>
        </SwiperSlide>
        <SwiperSlide>          
        <div classname="col-md-12">
            <div className=" mainwrap" style={{
              paddingBottom:`${percentage}`
            }}>
            <div className='wrap'>
            <a href={link2} target='_blank' >
            <div className='img'>
            <img src={slide2} alt="" srcset=""/>
            </div>
            </a>
            </div>
            </div>
            <div className="content">
            <h3 >{head2}
</h3>
<p>{para2}

</p>
{btn &&
  <button type="button" class="btn btn-outline-primary">see more article</button>
  }
    
        </div>
            
                </div>
</SwiperSlide>
        <SwiperSlide>    
        <div classname="col-md-12">
        <div className=" mainwrap" style={{
          paddingBottom:`${percentage}`
        }}>  
        <div className='wrap'>
        <a href={link3} target='_blank' >
        <div className='img'>
            <img src={slide3} alt="" srcset=""/>
            </div>
            </a>
            </div>
            </div>
            <div className="content">
            <h3 >{head3}
</h3>
<p>{para3}

</p>
{btn &&
  <button type="button" class="btn btn-outline-primary">see more article</button>
  }
    
            </div>
         
</div>
</SwiperSlide>

    {  slide4 &&  <SwiperSlide>   
        <div className="col-md-12"> 
        <div className=" mainwrap" style={{
          paddingBottom:`${percentage}`
        }}>  
        <div className='wrap'>
        <a href={link4} target='_blank' >
        <div className='img'>
            <img src={slide4} alt="" srcset=""/>
            </div>
            </a>
            </div>
            </div>
            <div className="content">
            <h3 >{head4}
</h3>
<p>{para4}

</p>
{btn &&
  <button type="button" class="btn btn-outline-primary">see more article</button>
  }
    
          </div>
       
           </div>
</SwiperSlide>}
     { slide5 &&
        <SwiperSlide>  
  <div className="col-md-12">
        <div className=" mainwrap" style={{
          paddingBottom:`${percentage}`
        }}>    
        <div className='wrap'>
        <a href={link5} target='_blank' >
        <div className='img'>
            <img src={slide5} alt="" srcset=""/>
            </div>
            </a>
            </div>
            </div>
            <div className="content">
            <h3 >{head5}
</h3>
<p>{para5}

</p>
{btn &&
  <button type="button" class="btn btn-outline-primary">see more article</button>
  }
        
          
               </div>
               </div>   
</SwiperSlide>}
     
    
      </Swiper>
    </div>
  )
}

export default DynamicSlider
