import React from 'react'
import Pagetitle from '../Component/Pagetitle'
import { Accordion } from 'react-bootstrap'

const UserGuide = () => {
  return (
    <div>
 <Pagetitle title="User's guide"/>
 <div className="container pt-5">
        <div className="row">
            <div className="custum-container" style={{
                maxWidth:"70%",
                margin:"0 auto",
                height:"500px"
            }}>
  <Accordion defaultActiveKey="0">
      <Accordion.Item  className='item-accordin' eventKey="0">
        <Accordion.Header> <h1>Step 1: Familiarize yourself with the key concepts</h1></Accordion.Header>
        <Accordion.Body>
       
     
            <p className='p-styleaccord' >Before diving into the resources, we recommend that you visit our "Key Concepts" section . Here you will find important information about the carbon footprint and the ESG approach. 
             </p>
           
  

           
     
   
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item  className='item-accordin' eventKey="1">
        <Accordion.Header><h1>Step 2: Explore the three main steps</h1> </Accordion.Header>
        <Accordion.Body>
   
       <ul>
     
     <li>
     1st  Calculate

     </li>
     <li>
     2º  Reduce 

     </li>
     <li>
     3º  Compensate
     </li>


</ul>       
 </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item  className='item-accordin' eventKey="2">
        <Accordion.Header><h1>Step 3: Explore additional information</h1> </Accordion.Header>
        <Accordion.Body>
   
 
       <ul>
     
     <li>
     1st  Calculate

     </li>
     <li>
     2º  Reduce 

     </li>
     <li>
     3º  Compensate
     </li>

 
</ul>        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            </div>
        </div>
      </div>
    </div>
  )
}

export default UserGuide
