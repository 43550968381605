/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Slider.css";
import myvideo from "./../assets/HomeVideo.mp4"
// import required modules
// import { Pagination, Navigation } from "swiper/modules";

const Slider = () => {
  return (
    <>
      <div className="video-container">
            <video autoPlay muted loop playsInline>
                <source src={myvideo} type="video/mp4"/>
                <source src={myvideo} type="video/webm"/>
                Your browser does not support the video tag.
            </video>
        </div>
      {/* <section class="rev_slider_wrapper">
        <div id="slider1" class="rev_slider" data-version="5.0">
          <ul>
            <li
              data-index="rs-1"
              data-transition="fade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="300"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <img
                src="./images/main-slider/slide3.jpg"
                alt=""
                title="Home Page 1"
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                data-bgparallax="0"
                class="rev-slidebg"
                data-no-retina
              />

              <div
                class="tp-caption   tp-resizeme"
                id="slide-1-layer-9"
                data-x="['left','left','left','left']"
                data-hoffset="['495','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['310','210','310','170']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-visibility="['on','on','off','off']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{ zIndex: "5" }}
              >
                <img
                  src="./images/main-slider/slide-bg.png"
                  alt=""
                  data-ww="['674px','674px','674px','674px']"
                  data-hh="['398px','398px','398px','398px']"
                  width="674"
                  height="398"
                  data-no-retina
                />{" "}
              </div>

              <div
                class="tp-caption ch_title   tp-resizeme"
                id="slide-1-layer-1"
                data-x="['left','left','left','left']"
                data-hoffset="['540','40','40','40']"
                data-y="['top','top','top','top']"
                data-voffset="['350','250','350','200']"
                data-fontsize="['55','50','45','25']"
                data-lineheight="['55','50','45','25']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":200,"speed":500,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":500,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: "6",
                  whiteSpace: "nowrap",
                  letterSpacing: "0px",
                }}
              >
                WE CARE ABOUT
              </div>

              <div
                class="tp-caption ch_title   tp-resizeme"
                id="slide-1-layer-2"
                data-x="['left','left','left','left']"
                data-hoffset="['550','40','40','40']"
                data-y="['top','top','top','top']"
                data-voffset="['420','330','410','245']"
                data-fontsize="['55','50','45','25']"
                data-lineheight="['55','50','45','25']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":200,"speed":500,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":500,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: "7",
                  whiteSpace: "nowrap",
                  letterSpacing: "0px",
                }}
              >
                YOUR CARGO{" "}
              </div>

              <div
                class="tp-caption ch_content   tp-resizeme"
                id="slide-1-layer-3"
                data-x="['left','left','left','left']"
                data-hoffset="['540','40','40','40']"
                data-y="['top','top','top','top']"
                data-voffset="['505','415','515','340']"
                data-fontsize="['24','24','18','18']"
                data-lineheight="['24','24','18','18']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":600,"speed":700,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":700,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: "8",
                  whiteSpace: "nowrap",
                  letterSpacing: "0px",
                }}
              >
                Dedicated employees, working in 06 regional
              </div>

              <div
                class="tp-caption ch_content   tp-resizeme"
                id="slide-1-layer-4"
                data-x="['left','left','left','left']"
                data-hoffset="['540','40','40','40']"
                data-y="['top','top','top','top']"
                data-voffset="['540','455','540','365']"
                data-fontsize="['24','24','18','18']"
                data-lineheight="['24','24','18','18']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":600,"speed":700,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":700,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: "9",
                  whiteSpace: "nowrap",
                  letterSpacing: "0px",
                }}
              >
                around the globe, deliver operational{" "}
              </div>

              <a
                class="tp-caption ch_button rev-btn "
                href="#"
                target="_blank"
                id="slide-1-layer-5"
                data-x="['left','left','left','left']"
                data-hoffset="['540','40','40','40']"
                data-y="['top','top','top','top']"
                data-voffset="['600','520','620','430']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="button"
                data-actions=""
                data-responsive_offset="on"
                data-responsive="off"
                data-frames='[{"delay":800,"speed":1000,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(255,255,255);bg:rgb(255,0,0);bc:rgb(255,0,0);bw:1 1 1 1;"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[12,12,12,12]"
                data-paddingright="[35,35,35,35]"
                data-paddingbottom="[12,12,12,12]"
                data-paddingleft="[35,35,35,35]"
                style={{
                  zIndex: "10",
                  whiteSpace: "nowrap",
                  letterSpacing: "px",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderColor: "rgb(255,255,255)",
                  outline: "none",
                  boxShadow: "none",
                  boxSizing: "border-box",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
                About us
              </a>

              <a
                class="tp-caption ch_button rev-btn "
                href="#"
                target="_blank"
                id="slide-1-layer-7"
                data-x="['left','left','left','left']"
                data-hoffset="['750','250','270','40']"
                data-y="['top','top','top','top']"
                data-voffset="['600','520','620','500']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="button"
                data-actions=""
                data-responsive_offset="on"
                data-responsive="off"
                data-frames='[{"delay":800,"speed":1000,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(255,255,255);bg:rgb(255,0,0);bc:rgb(255,0,0);bw:1 1 1 1;"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[12,12,12,12]"
                data-paddingright="[35,35,35,35]"
                data-paddingbottom="[12,12,12,12]"
                data-paddingleft="[35,35,35,35]"
                style={{
                    zIndex: "11",
                    whiteSpace: "nowrap",
                    letterSpacing: "px",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: "rgb(255,255,255)",
                    outline: "none",
                    boxShadow: "none",
                    boxSizing: "border-box",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
              >
                Request Quote{" "}
              </a>
            </li>

            <li
              data-index="rs-2"
              data-transition="fade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="300"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <img
                src="./images/main-slider/slide2.jpg"
                alt=""
                title="Home Page 1"
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                data-bgparallax="0"
                class="rev-slidebg"
                data-no-retina
              />

              <div
                class="tp-caption   tp-resizeme"
                id="slide-2-layer-9"
                data-x="['left','left','left','left']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['285','185','255','185']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-visibility="['on','on','off','off']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{zIndex: '5'}}
              >
                <img
                  src="./images/main-slider/slide-bg.png"
                  alt=""
                  data-ww="['755px','755px','755px','755px']"
                  data-hh="['446px','446px','446px','446px']"
                  width="674"
                  height="398"
                  data-no-retina
                />{" "}
              </div>

              <div
                class="tp-caption ch_title   tp-resizeme"
                id="slide-2-layer-1"
                data-x="['left','left','left','left']"
                data-hoffset="['40','40','40','40']"
                data-y="['top','top','top','top']"
                data-voffset="['340','250','320','220']"
                data-fontsize="['55','50','45','25']"
                data-lineheight="['55','50','45','25']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":200,"speed":500,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":500,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{zIndex: '6', whiteSpace: 'nowrap', letterSpacing: '0px'}}
              >
                SHARED & DEDICATED{" "}
              </div>

              <div
                class="tp-caption ch_title   tp-resizeme"
                id="slide-2-layer-2"
                data-x="['left','left','left','left']"
                data-hoffset="['40','40','40','40']"
                data-y="['top','top','top','top']"
                data-voffset="['410','330','380','280']"
                data-fontsize="['55','50','45','25']"
                data-lineheight="['55','50','45','25']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":200,"speed":500,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":500,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{zIndex: '7', whiteSpace: 'nowrap', letterSpacing: '0px'}}
              >
                WAREHOUSING{" "}
              </div>

              <div
                class="tp-caption ch_content   tp-resizeme"
                id="slide-2-layer-3"
                data-x="['left','left','left','left']"
                data-hoffset="['40','40','40','40']"
                data-y="['top','top','top','top']"
                data-voffset="['505','415','480','355']"
                data-fontsize="['24','24','24','18']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":600,"speed":700,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":700,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{zIndex: '8', whiteSpace: 'nowrap', letterSpacing: '0px'}}
              >
                Dedicated employees, working in 06 regional{" "}
              </div>

              <div
                class="tp-caption ch_content   tp-resizeme"
                id="slide-2-layer-4"
                data-x="['left','left','left','left']"
                data-hoffset="['40','40','40','40']"
                data-y="['top','top','top','top']"
                data-voffset="['545','455','515','385']"
                data-fontsize="['24','24','24','18']"
                data-lineheight="['24','24','24','18']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":600,"speed":700,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":700,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{zIndex: '9', whiteSpace: 'nowrap', letterSpacing: '0px'}}
              >
                around the globe, deliver operational{" "}
              </div>

              <a
                class="tp-caption ch_button rev-btn "
                href="#"
                target="_blank"
                id="slide-2-layer-5"
                data-x="['left','left','left','left']"
                data-hoffset="['40','40','40','40']"
                data-y="['top','top','top','top']"
                data-voffset="['615','520','590','450']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="button"
                data-actions=""
                data-responsive_offset="on"
                data-responsive="off"
                data-frames='[{"delay":800,"speed":1000,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(255,255,255);bg:rgb(255,0,0);bc:rgb(255,0,0);bw:1 1 1 1;"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[12,12,12,12]"
                data-paddingright="[35,35,35,35]"
                data-paddingbottom="[12,12,12,12]"
                data-paddingleft="[35,35,35,35]"
                style={{
                    zIndex: "10",
                    whiteSpace: "nowrap",
                    letterSpacing: "px",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: "rgb(255,255,255)",
                    outline: "none",
                    boxShadow: "none",
                    boxSizing: "border-box",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
              >
                About us{" "}
              </a>

              <a
                class="tp-caption ch_button rev-btn "
                href="#"
                target="_blank"
                id="slide-2-layer-7"
                data-x="['left','left','left','left']"
                data-hoffset="['250','250','270','40']"
                data-y="['top','top','top','top']"
                data-voffset="['615','520','590','530']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="button"
                data-actions=""
                data-responsive_offset="on"
                data-responsive="off"
                data-frames='[{"delay":800,"speed":1000,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(255,255,255);bg:rgb(255,0,0);bc:rgb(255,0,0);bw:1 1 1 1;"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[12,12,12,12]"
                data-paddingright="[35,35,35,35]"
                data-paddingbottom="[12,12,12,12]"
                data-paddingleft="[35,35,35,35]"
                style={{
                    zIndex: "11",
                    whiteSpace: "nowrap",
                    letterSpacing: "px",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: "rgb(255,255,255)",
                    outline: "none",
                    boxShadow: "none",
                    boxSizing: "border-box",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
              >
                Request Quote{" "}
              </a>
            </li>

            <li
              data-index="rs-3"
              data-transition="fade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="300"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <img
                src="./images/main-slider/slide1.jpg"
                alt=""
                title="Home Page 1"
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                data-bgparallax="0"
                class="rev-slidebg"
                data-no-retina
              />

              <div
                class="tp-caption   tp-resizeme"
                id="slide-3-layer-9"
                data-x="['left','left','left','left']"
                data-hoffset="['495','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['310','210','210','154']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-visibility="['on','on','off','off']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{zIndex: '5'}}
              >
                <img
                  src="./images/main-slider/slide-bg.png"
                  alt=""
                  data-ww="['674px','674px','674px','674px']"
                  data-hh="['398px','398px','398px','398px']"
                  width="674"
                  height="398"
                  data-no-retina
                />{" "}
              </div>

              <div
                class="tp-caption ch_title   tp-resizeme"
                id="slide-3-layer-1"
                data-x="['left','left','left','left']"
                data-hoffset="['540','40','40','40']"
                data-y="['top','top','top','top']"
                data-voffset="['350','250','250','210']"
                data-fontsize="['55','50','45','25']"
                data-lineheight="['55','50','45','25']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":200,"speed":500,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":500,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{zIndex: '6', whiteSpace: 'nowrap', letterSpacing: '0px'}}
              >
                WORLDWIDE{" "}
              </div>

              <div
                class="tp-caption ch_title   tp-resizeme"
                id="slide-3-layer-2"
                data-x="['left','left','left','left']"
                data-hoffset="['540','40','40','40']"
                data-y="['top','top','top','top']"
                data-voffset="['420','330','330','250']"
                data-fontsize="['55','50','45','25']"
                data-lineheight="['55','50','45','25']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":200,"speed":500,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":500,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{zIndex: '7', whiteSpace: 'nowrap', letterSpacing: '0px'}}
              >
                LOGISTIC SERVICES{" "}
              </div>

              <div
                class="tp-caption ch_content   tp-resizeme"
                id="slide-3-layer-3"
                data-x="['left','left','left','left']"
                data-hoffset="['540','40','40','40']"
                data-y="['top','top','top','top']"
                data-voffset="['505','415','415','315']"
                data-fontsize="['24','24','24','18']"
                data-lineheight="['24','24','24','18']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":600,"speed":700,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":700,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{zIndex: '8', whiteSpace: 'nowrap', letterSpacing: '0px'}}
              >
                Dedicated employees, working in 06 regional
              </div>

              <div
                class="tp-caption ch_content   tp-resizeme"
                id="slide-3-layer-4"
                data-x="['left','left','left','left']"
                data-hoffset="['540','40','40','40']"
                data-y="['top','top','top','top']"
                data-voffset="['545','455','455','340']"
                data-fontsize="['24','24','24','18']"
                data-lineheight="['24','24','24','18']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":600,"speed":700,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":700,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{zIndex: '9', whiteSpace: 'nowrap', letterSpacing: '0px'}}
              >
                around the globe, deliver operational
              </div>

              <a
                class="tp-caption ch_button rev-btn "
                href="#"
                target="_blank"
                id="slide-3-layer-5"
                data-x="['left','left','left','left']"
                data-hoffset="['540','40','40','40']"
                data-y="['top','top','top','top']"
                data-voffset="['610','520','520','400']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="button"
                data-actions=""
                data-responsive_offset="on"
                data-responsive="off"
                data-frames='[{"delay":800,"speed":1000,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(255,255,255);bg:rgb(255,0,0);bc:rgb(255,0,0);bw:1 1 1 1;"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[12,12,12,12]"
                data-paddingright="[35,35,35,35]"
                data-paddingbottom="[12,12,12,12]"
                data-paddingleft="[35,35,35,35]"
                style={{
                    zIndex: "10",
                    whiteSpace: "nowrap",
                    letterSpacing: "px",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: "rgb(255,255,255)",
                    outline: "none",
                    boxShadow: "none",
                    boxSizing: "border-box",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
              >
                About us{" "}
              </a>

              <a
                class="tp-caption ch_button rev-btn "
                href="#"
                target="_blank"
                id="slide-3-layer-7"
                data-x="['left','left','left','left']"
                data-hoffset="['750','250','270','40']"
                data-y="['top','top','top','top']"
                data-voffset="['610','520','520','480']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="button"
                data-actions=""
                data-responsive_offset="on"
                data-responsive="off"
                data-frames='[{"delay":800,"speed":1000,"text_c":"transparent","bg_c":"transparent","use_text_c":false,"use_bg_c":false,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"use_text_c":false,"use_bg_c":false,"text_c":"transparent","bg_c":"transparent","frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(255,255,255);bg:rgb(255,0,0);bc:rgb(255,0,0);bw:1 1 1 1;"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[12,12,12,12]"
                data-paddingright="[35,35,35,35]"
                data-paddingbottom="[12,12,12,12]"
                data-paddingleft="[35,35,35,35]"
                style={{
                    zIndex: "11",
                    whiteSpace: "nowrap",
                    letterSpacing: "px",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: "rgb(255,255,255)",
                    outline: "none",
                    boxShadow: "none",
                    boxSizing: "border-box",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
              >
                Request Quote{" "}
              </a>
            </li>
          </ul>
        </div>
      </section> */}
      {/* <Swiper
        pagination={{
          type: "progressbar",
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src="./assets/images/main-slider/slide1.jpg" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="./assets/images/main-slider/slide3.jpg" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="./assets/images/main-slider/slide3-2.jpg" alt="" />
        </SwiperSlide>
      </Swiper> */}
      
    </>
  );
};

export default Slider;
