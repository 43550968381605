import React from 'react'
import Pagetitle from '../Component/Pagetitle'
import Aboutus from '../Component/Aboutus'
import Features from '../Component/Features'
import ChooseUs from '../Component/ChooseUs'

const About = () => {
  return (
    <>
    <Pagetitle title="About Us"/>
    <Aboutus/>
    <Features/>
    <ChooseUs/>
    </>
  )
}

export default About