import React from "react";
import './Loader.css'

const Loader = () => {
  return (
    <>
      <div class="loading-window">
        <div class="car">
          <div class="strike"></div>
          <div class="strike strike2"></div>
          <div class="strike strike3"></div>
          <div class="strike strike4"></div>
          <div class="strike strike5"></div>
          <div class="car-detail spoiler"></div>
          <div class="car-detail back"></div>
          <div class="car-detail center"></div>
          <div class="car-detail center1"></div>
          <div class="car-detail front"></div>
          <div class="car-detail wheel"></div>
          <div class="car-detail wheel wheel2"></div>
        </div>

        <div class="text">
          <span>It will take 2 to 3 minutes...</span>
          {/* <span class="dots">...</span> */}
        </div>
      </div>
    </>
  );
};

export default Loader;
