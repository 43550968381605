export const Routes = [
  {
    label: "Almeria ↔ Oran via Trasmediterránea",
    value: "Almeria , Oran",
    cost: 0,
    time: 8.0,
    distance: 232,
    co2: 233,
  },
  {
    label: "Almería ↔ Ghazaouet via Trasmediterránea",
    value: "Almería , Ghazaouet",
    cost: 0,
    time: 8.0,
    distance: 198,
    co2: 200,
  },
  {
    label: "Almería ↔ Nador via Grandi Navi Veloci (GNV)",
    value: "Almería , Nador , (GNV)",
    cost: 0,
    time: 9.0,
    distance: 178,
    co2: 179,
  },
  {
    label: "Almería ↔ Nador via Trasmediterránea",
    value: "Almería , Nador",
    cost: 225,
    time: 6.0,
    distance: 178,
    co2: 179,
  },
  {
    label: "Barcelona ↔ Civitavecchia via Grimaldi",
    value: "Barcelona , Civitavecchia",
    cost: 1199,
    time: 27.0,
    distance: 813,
    co2: 820,
  },
  {
    label: "Barcelona ↔ Genoa via Grandi Navi Veloci (GNV)",
    value: "Barcelona , Genoa",
    cost: 450,
    time: 20.0,
    distance: 652,
    co2: 657,
  },
  {
    label: "Barcelona ↔ Las Palmas & Tenerife through Suardiaz Fleet",
    value: "Barcelona , Las Palmas",
    cost: 1935,
    time: 68.0,
    distance: 2245,
    co2: 2263,
  },
  {
    label: "Barcelona ↔ Livorno via Grimaldi **** (€29.3) With ECO-INCENTIVE! ",
    value: "Barcelona , Livorno",
    cost: 1058,
    time: 21.0,
    distance: 704,
    co2: 709,
  },
  {
    label: "Barcelona ↔ Porto Torres via GRIMALDI",
    value: "Barcelona , Porto",
    cost: 1181,
    time: 18.0,
    distance: 526,
    co2: 530,
  },
  {
    label: "Barcelona ↔ Rades through Compagnie Tunisienne de Navigation (CTN)",
    value: "Barcelona , Rades",
    cost: 782,
    time: 54.0,
    distance: 1193,
    co2: 1202,
  },
  {
    label: "Barcelona ↔ Savona via Grimaldi **** (€26.2) With ECO-INCENTIVE! ",
    value: "Barcelona , Savona",
    cost: 786,
    time: 34.0,
    distance: 585,
    co2: 590,
  },
  {
    label: "Barcelona ↔ Tangier via Grimaldi ",
    value: "Barcelona , Tangier",
    cost: 860,
    time: 26.0,
    distance: 998,
    co2: 1006,
  },
  {
    label: "Barcelona ↔ Tangier via Grandi Navi Veloci (GNV) ",
    value: "Barcelona , Tangier , (GNV)",
    cost: 1540,
    time: 40.0,
    distance: 998,
    co2: 1006,
  },
  {
    label: "Bilbao ↔ Amberes a través de Finnlines & Birka Cargo",
    value: "Bilbao , Amberes",
    cost: 550,
    time: 60.0,
    distance: 1437,
    co2: 1449,
  },
  {
    label: "Bilbao ↔ Antwerp via Finnlines & Birka Cargo",
    value: "Bilbao , Antwerp",
    cost: 550,
    time: 60.0,
    distance: 1437,
  },
  {
    label: "Bilbao ↔ Gothenburg via Finnlines & Birka Cargo",
    value: "Bilbao , Gothenburg",
    cost: 1275,
    time: 93.0,
    distance: 3032,
    co2: 3056,
  },
  {
    label:
      "Bilbao ↔ Helsinki through Finnlines & Birka Cargo **** (€5.6) With ECO-INCENTIVE! ",
    value: "Bilbao , Helsinki",
    cost: 2800,
    time: 228.0,
    distance: 3467,
    co2: 3495,
  },
  {
    label: "Bilbao ↔ Hull via Finnlines & Birka Cargo ",
    value: "Bilbao , Hull",
    cost: 1124,
    time: 2.0,
    distance: 1561,
    co2: 1574,
  },
  {
    label:
      "Bilbao ↔ Kotka through Finnlines & Birka Cargo **** (€5.6) With ECO-INCENTIVE!  ",
    value: "Bilbao , Kotka",
    cost: 2800,
    time: 204.0,
    distance: 3569,
    co2: 3597,
  },
  {
    label: "Bilbao ↔ Lubeck via Finnlines  ",
    value: "Bilbao , Lubeck",
    cost: 700,
    time: 72.9,
    distance: 2359,
    co2: 2378,
  },

  {
    label: "Bilbao ↔ Paldiski via Finnlines **** (€5.6) With ECO-INCENTIVE!   ",
    value: "Bilbao , Paldiski",
    cost: 2800,
    time: 252.0,
    distance: 3371,
    co2: 3398,
  },
  {
    label: "Bilbao ↔ Poole via Brittany Ferries",
    value: "Bilbao , Poole",
    cost: 996,
    time: 34.0,
    distance: 1093,
    co2: 1101,
  },
  {
    label: "Bilbao ↔ Portsmouth via Brittany Ferries",
    value: "Bilbao , Portsmouth",
    cost: 1036,
    time: 26.0,
    distance: 1028,
    co2: 1036,
  },
  {
    label:
      "Bilbao ↔ Rosslare via Brittany Ferries **** (€6.8) With ECO-INCENTIVE! ",
    value: "Bilbao , Rosslare",
    cost: 1688,
    time: 28.0,
    distance: 1046,
    co2: 1055,
  },
  {
    label: "Bilbao ↔ Saint Petersburg via Finnlines & Birka Cargo",
    value: "Bilbao , Saint Petersburg",
    cost: 2800,
    time: 181.0,
    distance: 3728,
    co2: 3758,
  },
  {
    label: "Bilbao ↔ Tilbury via Finnlines & Birka Cargo",
    value: "Bilbao , Tilbury",
    cost: 1027,
    time: 68.4,
    distance: 2076,
    co2: 2093,
  },
  {
    label: "Ferrol ↔ Helsinki via Finnlines & Birka Cargo",
    value: "Ferrol , Helsinki",
    cost: 2800,
    time: 228.0,
    distance: 3467,
    co2: 3495,
  },
  {
    label: "Ferrol ↔ Kotka via UPM Seaways",
    value: "Ferrol , Kotka",
    cost: 2600,
    time: 102.0,
    distance: 3569,
    co2: 3597,
  },
  {
    label: "Ferrol ↔ Saint Petersburg via Finnlines",
    value: "Ferrol , Saint Petersburg",
    cost: 2800,
    time: 181.0,
    distance: 3728,
    co2: 3758,
  },
  {
    label: "Huelva ↔ Hamburg via CONTAINERSHIPS-CMA CGM SAU",
    value: "Huelva , Hamburg , SAU",
    cost: 850,
    time: 146.0,
    distance: 3143,
    co2: 3168,
  },
  {
    label: "Huelva ↔ Hamburg via Containerships",
    value: "Huelva , Hamburg",
    cost: 850,
    time: 146.0,
    distance: 3143,
    co2: 3168,
  },
  {
    label: "Huelva ↔ Rotterdam through CONTAINERSHIPS-CMA CGM SAU",
    value: "Huelva , Rotterdam , SAU",
    cost: 850,
    time: 123.0,
    distance: 2578,
    co2: 2599,
  },
  {
    label: "Huelva ↔ Rotterdam via Containerships",
    value: "Huelva , Rotterdam",
    cost: 850,
    time: 123.0,
    distance: 2578,
    co2: 2599,
  },
  {
    label: "Huelva ↔ Tilbury via Containerships",
    value: "Huelva , Tilbury",
    cost: 850,
    time: 110.0,
    distance: 2271,
    co2: 2289,
  },
  {
    label: "Huelva ↔ Tilbury via Containerships – CMA CGM SAU",
    value: "Huelva , Tilbury , SAU",
    cost: 850,
    time: 110.0,
    distance: 2271,
    co2: 2289,
  },
  {
    label: "Motril ↔ Al Hoceima via Trasmediterránea",
    value: "Motril , Al Hoceima",
  },
  {
    label: "Motril ↔ Alhucemas a través de Trasmediterránea",
    value: "Motril ↔ Alhucemas",
    cost: 280,
    time: 5.0,
    distance: 167,
    co2: 168,
  },
  {
    label: "Motril ↔ Nador via Trasmediterránea",
    value: "Motril , Nador",
    cost: 300,
    time: 7.0,
    distance: 241,
    co2: 243,
  },
  {
    label: "Sagunto ↔ Cagliari via Grimaldi",
    value: "Sagunto , Cagliari",
    cost: 1169,
    time: 30.0,
    distance: 843,
    co2: 849,
  },
  {
    label: "Sagunto ↔ Salerno via Grimaldi **** (€41.7) With ECO-INCENTIVE! ",
    value: "Sagunto , Salerno",
    cost: 1209,
    time: 52.0,
    distance: 1315,
    co2: 1325,
  },
  {
    label: "Santander ↔ Dublin via CLdN **** (€60) With ECO-INCENTIVE! ",
    value: "Santander , Dublin",
    cost: 700,
    time: 72.0,
    distance: 1128,
    co2: 1137,
  },
  {
    label: "Santander ↔ Gdynia via UPM Seaways",
    value: "Santander , Gdynia",
    cost: 2600,
    time: 84.0,
    distance: 2891,
    co2: 2914,
  },
  {
    label: "Santander ↔ Helsinki via Finnlines",
    value: "Santander , Helsinki",
    cost: 2800,
    time: 228.0,
    distance: 3424,
    co2: 3452,
  },
  {
    label: "Santander ↔ Kotka via UPM Seaways",
    value: "Santander , Kotka",
    cost: 2600,
    time: 101.0,
    distance: 3526,
    co2: 3554,
  },
  {
    label: "Santander ↔ Paldiski via Finnlines",
    value: "Santander , Paldiski",
    cost: 2800,
    time: 252.0,
    distance: 3328,
    co2: 3355,
  },
  {
    label: "Santander ↔ Plymouth via Brittany Ferries",
    value: "Santander , Plymouth",
    cost: 1000,
    time: 22.0,
    distance: 796,
    co2: 803,
  },
  {
    label: "Santander ↔ Portsmouth via Brittany Ferries",
    value: "Santander , Portsmouth",
    cost: 1086,
    time: 29.0,
    distance: 985,
    co2: 993,
  },
  {
    label: "Santander ↔ Rauma via UPM Seaways",
    value: "Santander , Rauma",
    cost: 2600,
    time: 98.0,
    distance: 3417,
    co2: 3444,
  },
  {
    label: "Santander ↔ Saint Petersburg via Finnlines",
    value: "Santander , Saint Petersburg",
    cost: 2600,
    time: 105.0,
    distance: 3685,
    co2: 3715,
  },
  {
    label: "Santander ↔ Saint Petersburg via UPM Seaways",
    value: "Santander , Saint Petersburg , Seaways",
    cost: 2800,
    time: 181.0,
    distance: 3685,
    co2: 3715,
  },
  {
    label: "Santander ↔ Zeebrugge via CLdN **** (€50.2) With ECO-INCENTIVE! ",
    value: "Santander , Zeebrugge",
  },
  {
    label: "Tarragona ↔ Izmir via DFDS Seaways",
    value: "Tarragona , Izmir",
    cost: 1500,
    time: 62.0,
    distance: 2500,
    co2: 2520,
  },
  {
    label: "Valencia ↔ Livorno via Grimaldi **** (€102.8) With ECO-INCENTIVE! ",
    value: "Valencia , Livorno",
    cost: 1246,
    time: 30.0,
    distance: 989,
    co2: 997,
  },
  {
    label:
      "Valencia ↔ Savona through GRIMALDI **** (€101.2) With ECO-INCENTIVE! ",
    value: "Valencia , Savona",
    cost: 1278,
    time: 46.0,
    distance: 874,
    co2: 881,
  },
  {
    label: "Vigo ↔ Saint Nazaire via Suardiaz Fleet",
    value: "Vigo , Saint Nazaire",
    cost: 750,
    time: 34.0,
    distance: 857,
    co2: 864,
  },
  {
    label: "Vigo ↔ Tangier via Suardiaz Fleet",
    value: "Vigo , Tangier",
    cost: 750,
    time: 42.0,
    distance: 922,
    co2: 930,
  },
];

export const RailywayRoutes = [
  {
    label: "Le Boulou – Bettembourg – Size of trailer 45",
    value: "Le Boulou , Bettembourg",
    cost: 700,
    time: 42.0,
    distance: 1200,
    co2: 1148.9377032,
    name: "Le Boulou Bettembourg Size of trailer 45 ",
    // name: "Le Boulou – Bettembourg – Size of trailer 45 ",
  },
  {
    label: "Le Boulou – Bettembourg – Size of trailer 40",
    value: "Le Boulou , Bettembourg",
    cost: 700,
    time: 42.0,
    distance: 1200,
    co2: 1148.9377032,
    name: "Le Boulou Bettembourg Size of trailer 40 ",
  },
  {
    label: "Le Boulou – Bettembourg – Size of trailer 30 - 25 - 23",
    value: "Le Boulou , Bettembourg",
    cost: 600,
    time: 42.0,
    distance: 1200,
    co2: 1148.9377032,
    name: "Le Boulou Bettembourg Size of trailer 30",
  },
  {
    label: "Le Boulou – Bettembourg – Size of trailer 20",
    value: "Le Boulou , Bettembourg",
    cost: 550,
    time: 42.0,
    distance: 1200,
    co2: 1148.9377032,
    name: "Le Boulou Bettembourg Size of trailer 20 ",
  },
  
  {
    label: "Le Boulou - Calais - Size of trailer 45",
    value: "Le Boulou , Calais ",
    cost: 710,
    time: 42.0,
    distance: 1200,
    co2: 1148.9377032,
    check : 45,
    name: "Le Boulou Calais Size of trailer 45" ,
  },
  {
    label: "Le Boulou – Calais – Size of trailer 40",
    value: "Le Boulou , Calais ",
    cost: 710,
    time: 42.0,
    distance: 1200,
    co2: 1148.9377032,
    name: "Le Boulou Calais Size of trailer 40" ,
  },
  {
    label: "Le Boulou – Calais – Size of trailer 30 - 25 - 23",
    value: "Le Boulou , Calais",
    cost: 610,
    time: 42.0,
    distance: 1200,
    co2: 1148.9377032,
    name: "Le Boulou Calais Size of trailer 30" ,
  },
  {
    label: "Le Boulou – Calais – Size of trailer 20",
    value: "Le Boulou , Calais",
    cost: 560,
    time: 42.0,
    distance: 1200,
    co2: 1148.9377032,
    name: "Le Boulou Calais Size of trailer 20" ,
  },
];
