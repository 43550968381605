import React from "react";

const Sectionser = () => {
  return (
    <>
      {/* <!--services Welcome sec --> */}
      <div class="servwesec secpadd">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="uthead lftredbrdr">
                <p>Unbeatable Trucking and Transport Services</p>
              </div>
              <div class="mdltxtnrow">
                Transport-360 is world leading logistic service company and{" "}
                <span class="main-color">provide #1 Solution.</span>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <p>
                Transport-360 is the world’ s leading logistic service company, We
                have a wide experience in overland industry specific logistic
                solutions like pharmaceutical logistics, retail and automotive
                logistics by train or road.
              </p>
              <p>
                We bring your goods safely to worldwide destinations with our
                great sea fright services.We offer LLC and FLC shipments that
                are fast and effective with no delays.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Welcome sec   end--> */}
    </>
  );
};

export default Sectionser;
