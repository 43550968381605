/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Sercard = () => {
  return (
    <>
      {/* <!-- services sec --> */}
      <div class="allservsec">
        <div class="container">
          <div class="fh-service  style-bordered">
            <div class="service-list row">
              <div class="item-service  col-xs-12 col-sm-6 col-md-4">
                <div class="service-content">
                  <div class="entry-thumbnail">
                    <a class="link" href="#"></a>
                    <a href="#">
                      <span class="icon">
                        <i
                          class="fa fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                    <img src="./assets/images/services/serv-1.jpg" alt="" />
                  </div>
                  <div class="summary">
                    <h2 class="entry-title">
                      <a href="#">Road Freight Forwarding</a>
                    </h2>
                    <p>
                      Cargo are transported at some stage of their journey along
                      the world’s roads where we give you a presence.
                    </p>
                    <a href="#" class="read-more">
                      Read More...
                    </a>
                  </div>
                </div>
              </div>
              <div class="item-service  col-xs-12 col-sm-6 col-md-4">
                <div class="service-content">
                  <div class="entry-thumbnail">
                    <a class="link" href="#"></a>
                    <a href="#">
                      <span class="icon">
                        <i
                          class="fa fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                    <img src="./assets/images/services/serv-2.jpg" alt="" />
                  </div>
                  <div class="summary">
                    <h2 class="entry-title">
                      <a href="#">Ocean Freight Forwarding</a>
                    </h2>
                    <p>
                      Ocean Freight plays perhaps the most vital role in most
                      transportation and supply chain solutions.
                    </p>
                    <a href="#" class="read-more">
                      Read More...
                    </a>
                  </div>
                </div>
              </div>
              <div class="item-service  col-xs-12 col-sm-6 col-md-4">
                <div class="service-content">
                  <div class="entry-thumbnail">
                    <a class="link" href="h.html#"></a>
                    <a href="#">
                      <span class="icon">
                        <i
                          class="fa fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                    <img src="./assets/images/services/serv-3.jpg" alt="" />
                  </div>
                  <div class="summary">
                    <h2 class="entry-title">
                      <a href="#">Air Freight Forwarding</a>
                    </h2>
                    <p>
                      As a leader in global air freight forwarding, OIA Global
                      excels in providing tailored transportation
                    </p>
                    <a href="#" class="read-more">
                      Read More...
                    </a>
                  </div>
                </div>
              </div>
              <div class="item-service  col-xs-12 col-sm-6 col-md-4">
                <div class="service-content">
                  <div class="entry-thumbnail">
                    <a class="link" href="#"></a>
                    <a href="#">
                      <span class="icon">
                        <i
                          class="fa fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                    <img src="./assets/images/services/serv-3.jpg" alt="" />
                  </div>
                  <div class="summary">
                    <h2 class="entry-title">
                      <a href="#">Warehousing</a>
                    </h2>
                    <p>
                      Package and store your things effectively and securely to
                      make sure them in storage, have certified warehouse.
                    </p>
                    <a href="#" class="read-more">
                      Read More...
                    </a>
                  </div>
                </div>
              </div>
              <div class="item-service  col-xs-12 col-sm-6 col-md-4">
                <div class="service-content">
                  <div class="entry-thumbnail">
                    <a class="link" href="#"></a>
                    <a href="#">
                      <span class="icon">
                        <i
                          class="fa fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                    <img src="./assets/images/services/serv-5.jpg" alt="" />
                  </div>
                  <div class="summary">
                    <h2 class="entry-title">
                      <a href="#">Door to Door Delivery</a>
                    </h2>
                    <p>
                      Our expertise in transport management and planning allows
                      us to design a solution. hand over the parcel at your
                      door.
                    </p>
                    <a href="#" class="read-more">
                      Read More...
                    </a>
                  </div>
                </div>
              </div>
              <div class="item-service  col-xs-12 col-sm-6 col-md-4">
                <div class="service-content">
                  <div class="entry-thumbnail">
                    <a class="link" href="h.html#"></a>
                    <a href="#">
                      <span class="icon">
                        <i
                          class="fa fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                    <img src="./assets/images/services/serv-6.jpg" alt="" />
                  </div>
                  <div class="summary">
                    <h2 class="entry-title">
                      <a href="#">Ground Transport</a>
                    </h2>
                    <p>
                      Ground transportation options for all visitors, no matter
                      your needs, schedule or destination.
                    </p>
                    <a href="#" class="read-more">
                      Read More...
                    </a>
                  </div>
                </div>
              </div>
              <div class="item-service  col-xs-12 col-sm-6 col-md-4">
                <div class="service-content">
                  <div class="entry-thumbnail">
                    <a class="link" href="#"></a>
                    <a href="#">
                      <span class="icon">
                        <i
                          class="fa fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                    <img src="./assets/images/services/serv-7.jpg" alt="" />
                  </div>
                  <div class="summary">
                    <h2 class="entry-title">
                      <a href="#">Worldwide Transport</a>
                    </h2>
                    <p>
                      Specialises in international freight forwarding of
                      merchandise and associated general and all logistic
                      services.
                    </p>
                    <a href="#" class="read-more">
                      Read More...
                    </a>
                  </div>
                </div>
              </div>
              <div class="item-service  col-xs-12 col-sm-6 col-md-4">
                <div class="service-content">
                  <div class="entry-thumbnail">
                    <a class="link" href="#"></a>
                    <a href="#">
                      <span class="icon">
                        <i
                          class="fa fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                    <img src="./assets/images/services/serv-8.jpg" alt="" />
                  </div>
                  <div class="summary">
                    <h2 class="entry-title">
                      <a href="#">Cargo Service</a>
                    </h2>
                    <p>
                      Delivery of any freight from one place to another place
                      quickly to save your cost and save your valuable time.
                    </p>
                    <a href="#" class="read-more">
                      Read More...
                    </a>
                  </div>
                </div>
              </div>
              <div class="item-service  col-xs-12 col-sm-6 col-md-4">
                <div class="service-content">
                  <div class="entry-thumbnail">
                    <a class="link" href="h.html#"></a>
                    <a href="#">
                      <span class="icon">
                        <i
                          class="fa fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                    <img src="./assets/images/services/serv-9.jpg" alt="" />
                  </div>
                  <div class="summary">
                    <h2 class="entry-title">
                      <a href="#">Packaging & Storage</a>
                    </h2>
                    <p>
                      Package and store your things effectively and securely to
                      make sure them in storage, We guranteed for 100%.
                    </p>
                    <a href="#" class="read-more">
                      Read More...
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- services sec end --> */}
    </>
  );
};

export default Sercard;
