import React from "react";

const ChooseUs = () => {
  return (
    <>
      {/* <!-- featured sec --> */}
      <section class="whychoos-3 secpadd">
        <div class="container">
          <div class="fh-section-title clearfix  text-center version-dark paddbtm30">
            <h2>Why Choose Us</h2>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-6">
              <div class="fh-icon-box  style-2 version-dark  icon-center">
                <span class="fh-icon">
                  <i class="flaticon-international-delivery"></i>
                </span>
                <h4 class="box-title">
                  <span>24 Hours Support</span>
                </h4>
                <div class="desc">
                  <p>
                    We are Specialises in international freight forwarding of
                    merchandise.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="fh-icon-box  style-2 version-dark  icon-center">
                <span class="fh-icon">
                  <i class="flaticon-people"></i>
                </span>
                <h4 class="box-title">
                  <span>Global supply Chain</span>
                </h4>
                <div class="desc">
                  <p>
                    Efficiently unleash cross-media information without
                    cross-media value.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="fh-icon-box  style-2 version-dark  icon-center">
                <span class="fh-icon">
                  <i class="flaticon-route"></i>
                </span>
                <h4 class="box-title">
                  <span>Mobile Shipment Tracking</span>
                </h4>
                <div class="desc">
                  <p>
                    We Offers intellgent concepts for road & tail well as
                    complex special services.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-md-offset-2">
              <div class="fh-icon-box  style-2 version-dark  icon-center">
                <span class="fh-icon">
                  <i class="flaticon-open-cardboard-box"></i>
                </span>
                <h4 class="box-title">
                  <span>Careful Handling</span>
                </h4>
                <div class="desc">
                  <p>
                    Transport-360 are transported at some stage of their journey
                    along world’s roads.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="fh-icon-box  style-2 version-dark  icon-center">
                <span class="fh-icon">
                  <i class="flaticon-alarm-clock"></i>
                </span>
                <h4 class="box-title">
                  <span>Time On Door Delivery</span>
                </h4>
                <div class="desc">
                  <p>
                    We Offers intellgent concepts for road & tail well as
                    complex special services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- featured sec end --> */}
    </>
  );
};

export default ChooseUs;
