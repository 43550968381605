import React from 'react'

import TransportBannner from '../Component/Transport360/TransportBannner'
import girlimg from "./../assets/news.jpg"
import SectionHeader from '../Component/Transport360/SectionHeader'
import slide1 from "../assets/articleslide1.webp"
import slide2 from "../assets/articleslide2.webp"
import slide3 from "../assets/articleslide3.webp"
import slide4 from "../assets/articleslide4.webp"
import slide5 from "../assets/articleslide5.webp"
import slide6 from "../assets/articleslide6.webp"
import slide7 from "../assets/articleslide7.webp"
import slide8 from "../assets/articleslide8.webp"
import slide9 from "../assets/articleslide9.webp"
import DynamicSlider from '../Component/Transport360/DynamicSlider'


const Faqs = () => {
  return (
    <>
   <TransportBannner backimg={girlimg}  header={"Newsletter"} />
   <SectionHeader head={" ARTICLES"}/>
   <div className='pt-5 pb-5'>
<DynamicSlider percentage={"40%"} slide1={slide1} btn={true} head1={"Correos Spain faces logistical challenges in delivering votes by mail for the July 23 elections"}
link1={"https://www.linkedin.com/pulse/correos-espa%C3%B1a-enfrenta-retos-en-la-entrega-de-votos-por"}
link2={"https://www.linkedin.com/pulse/correos-espa%C3%B1a-enfrenta-retos-en-la-entrega-de-votos-por"}
link3={"https://www.linkedin.com/pulse/interoperability-overview-2023-transporte360"}
link4={""}
link5={"https://www.linkedin.com/pulse/tech4fleet-2023-un-vistazo-al-futuro-de-la-gesti%C3%B3n-flotas"}
slide2={slide2} heade2={"Transport and logistics: A booming sector that seeks talent and innovation"} para2={"Did you know that transportation and logistics are one of the sectors with the most demand for employment in Spain?"}
slide3={slide3} head3={"Interoperability Overview 2023"} para3={"Discover the latest advances in safety and interoperability in the European railway sector!"}
slide4={slide4} head4={"Spain leads the way towards sustainable maritime transport with hydroge"}
slide5={slide5} head5={"Tech4Fleet 2023: A look at the future of fleet management"}

/>
</div>
<div className='pt-5 pb-5'>
<SectionHeader head={" NEWS"}/>
<DynamicSlider 
link1={"https://www.linkedin.com/posts/transporte360_logisticaaerea-madridbarajas-aena-activity-7089911936347176964-nnpP?utm_source=share&utm_medium=member_desktop"}
link2={"https://www.linkedin.com/feed/update/urn:li:activity:7084788551472623616"}
link3={"https://www.linkedin.com/feed/update/urn:li:activity:7084442900477976577"}
link4={"https://www.linkedin.com/feed/update/urn:li:activity:7082097497858355200"}
percentage={"40%"} slide1={slide6} btn={true}  head1={" Adolfo Suárez Madrid-Barajas Airport Air Cargo Center"} para1={"Last week, the Transporte360 team had the opportunity to explore the vibrant world of air logistics at the Air Cargo Center of the Adolfo Suárez Madrid-Barajas Airport....."}
slide2={slide7} head2={"SFERA EXPERIENCE "} para2={" Diego Galdeano De León. Youssef Benlali Benabed What would we do without you! Our founders have been part of SFERA EXPERIENCE, a collaborative space for social innovation where its participants work together to face challenges....."}
slide3={slide8} head3={"Monthly Report: June"} para3={"Dear followers, we are excited to share with you the latest data regarding our growth on the platform in the month of June. "}
slide4={slide9} head4={"How do you think automation will affect logistics in the near future?"}

/>
</div>
    </>
  )
}

export default Faqs