import React from 'react'
import TransportBannner from '../Component/Transport360/TransportBannner'
import girlimg from "./../assets/girl.webp"
import SectionHeader from '../Component/Transport360/SectionHeader'
import DynamicSlider from '../Component/Transport360/DynamicSlider'
import slide1 from "../assets/compenslide1.webp"
import slide2 from "../assets/compenslide2.webp"
import slide3 from "../assets/compenslide3.webp"
import slide4 from "../assets/compenslide4.webp"
import slide5 from "../assets/compenslide5.webp"
import slide6 from "../assets/compenslide6.webp"
import slide7 from "../assets/compenslider7.webp"
import slide8 from "../assets/compenslide8.webp"
import slide9 from "../assets/compenslider9.webp"
import slide10 from "../assets/compenslide10.webp"
import slide11 from "../assets/compenslide11.webp"
import slide12 from "../assets/compenslide12.webp"
import slide13 from "../assets/compenslide13.webp"
const CalculateCompensate = () => {
  return (
    <div>
   <TransportBannner backimg={girlimg}  header={"Calculate, Reduce and Compensate"} />
       <SectionHeader head={" Carbon Footprint Calculation"}/>
  <div className='py-5'>
        <DynamicSlider slide1={slide1} slide2={slide2} slide3={slide3} slide4={slide4} slide5={slide5} 
        link1={"https://www.aecoc.es/guias/guia-tecnica-de-calculo-de-la-huella-de-carbono-del-transporte-de-mercancias-por-carretera/"}
        link2={"https://www.miteco.gob.es/es/cambio-climatico/temas/mitigacion-politicas-y-medidas/calculadoras.aspx"}
        link3={"https://ghgprotocol.org/calculation-tools-and-guidance"}
        link4={"https://www.miteco.gob.es/es/cambio-climatico/temas/mitigacion-politicas-y-medidas/factoresemision_tcm30-479095.pdf"}
        link5={""}
        head1={"Technical guide for calculating the carbon footprint of road freight transport"} para1={"This guide is structured as a manual for calculating fuel consumption and greenhouse gas (GHG) emissions, or Carbon footprint, of any shipment transported by road. "}
        head2={"Ministry Calculator"} para2={"This calculator allows you to easily estimate the greenhouse gas emissions associated with the activities of an organization, considering both direct emissions and indirect emissions from electricity consumption. Does not include the calculation of scope 3 emissions."}
        head3={"The GHG Protocol Tools "} para3={"An accurate and useful inventory to consult the GHG Protocol corporate accounting and reporting standard. The link shows a complete list of all the tools developed by GHG Protocol: Cross-cutting tools, country-specific tools, sector-specific tools, tools for countries and cities."}
        head4={"Emission Factors"} para4={" You will find in this document the emission factors to be used as well as the sources from which they have been obtained. "}
        head5={" You will find in this document the emission factors to be used as well as the sources from which they have been obtained."}
        />
      </div>
      <div className='py-5' style={{
        marginTop:"5rem",
        marginBottom:"5rem"
      }}>
         <SectionHeader head={"Carbon Footprint Reduction "}/>
</div>
      <DynamicSlider slide1={slide6} slide2={slide7} slide3={slide8} slide4={slide9} slide5={slide10}  
      link1={"https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fwww.miteco.gob.es%2Fes%2Fcambio-climatico%2Ftemas%2Fmitigacion-politicas-y-medidas%2Fcalculadorahc_reduccion_tcm30-485622.xlsx&wdOrigin=BROWSELINK"}
      link2={"https://www.cargobull.com/fr/produits/curtainsider/sattelcurtainsider/ecogeneration"}
      link3={"http://www.shortsea.es/index.php/summary-in-english"}
      link4={"https://www.seur.com/uploads/files/carta-homologacion-sin_1635513910.pdf"}
      link5={"https://www.volvotrucks.es/es-es/trucks/alternative-fuels/environmental-footprint-calculator/footprint-calculator.html"}
        head1={"Carbon Footprint Reduction Calculator (miteco)"} para1={"Like all great things in life, our project started small. We bring together talents with a common vision and we go for it to be something great."}
        head2={"EcoGeneration"} para2={"EcoGeneration is a more sustainable type of canvas trailer from the semi-trailer manufacturing company Schmitz Cargobull. This design is built on sustainability and efficiency. Its main feature is the aerodynamic structure, which offers fuel savings and, therefore, Co2eq emissions. Their website offers its own calculator, which estimates the reduction with the use of this trailer. "}
        head3={"Short Sea Shipping (Intermodality)"} para3={" Intermodality in transport refers to the joint and combined use of different means of transport, taking advantage of the advantages of each one. This could help with sustainability in reducing greenhouse gas emissions emitted in road transport. The Short Sea Shipping tool offers a simulator that compares intermodality versus road transport. "}
        head4={"Supplier Approval Letter (Scope 3)"} para4={"  Reducing Scope 3 emissions entails great difficulty, this is due to several reasons, but the main one of all is the lack of direct control in the operations of service providers, which means that they cannot be implemented. directly mitigation or reduction measures of emissions.Establish a “supplier approval letter” so that they are the ones who report their data if they want to work with your company and, in this way, the company has control of its service providers without directly affecting their operations.  Example letter: SEUR "}
        head5={" Calculator of the impact of your fleet of electric tractors"} para5={" This calculator offered by Volvo allows you to calculate how many tons of Co2 you stop emitting when using an electric vehicle versus a combustion vehicle. "}
        />
 <div className='py-5' style={{
        marginTop:"5rem",
        marginBottom:"5rem"
      }}>
         <SectionHeader head={" Carbon Footprint Offsetting "}/>
</div>
      <DynamicSlider slide1={slide11} slide2={slide12} slide3={slide13}  

        head1={"Plant For The Planet"} para1={" Plant for the Planet offers a service to help businesses offset their carbon footprint through tree planting.  This carbon offset program allows you to purchase carbon credits. These credits are used to finance the planting of trees in different parts of the world. "}
        head2={"Sylvestris Group"} para2={" It is a company that maintains a collaboration with the Repsol group with more than 30 years of experience in the reforestation sector. They have also launched the “Renaturize our cities” movement to provide cities with green spaces by building green roofs, vertical gardens and urban gardens, which have previously been removed by the construction of new buildings, etc.  "}
        head3={"Green Bonds"} para3={" Green bonds are any type of bond whose funds are intended exclusively to finance or refinance, in part or in whole, eligible green projects, whether new and/or existing. In addition, they must be aligned with the Green Bond Principles (GBP). The vast majority of Green Bonds are issued by banks. "}

        /> 

      </div>

  )
}

export default CalculateCompensate
