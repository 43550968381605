/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
const TransportBannner = (props) => {
    const {backimg,header,para}=props
  return (
    <div>
  <section
    className="l7Lyt hugeTitleFontSize css-1thnnrz ntvkk css-iercv5 block"
    data-block-type="cover"
    id="1336bed3-fbfb-4bc6-bdd9-1d9f8ec8d13d"
  >
    <div className="hIG3J D3TBu RgGME">
      <div className="HNCFr">
        <img
          className="IVe+H"
          draggable="false"
       src={backimg}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="tgvwX css-pfoau7" />
      <div className="_4ebAt">
        <div className="HvmIt _5fS79 DpJiu">
          <div className="jDtdS">
            <div className="_6OU6x" data-testid="static-text-element">
              <div className="_6L72f">
                <div className="pt-5">
              { header &&    <h1 className="text-align-center py-5" style={{
                    textAlign:"center"
                  }}>
           
                       {header}
                    
                  </h1>}
               {  para &&  <p className="text-align-center " style={{
                    textAlign:"center"
                  }}>
                    <strong>
                      
                      {para}
                     
                    </strong>
                  </p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="arREF">
      <a href="#" className="_4MiO+">
        <span className="Gcq-- JdkRb jw287">
          <svg
            width="14px"
            height="7px"
            viewBox="0 0 14 7"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
              <g
                transform="translate(-713.000000, -974.000000)"
                className="rs4FJ EY9Hl"
              >
                <g id="Rectangle-2">
                  <polygon points="720 981.071068 712.928932 974 727.071068 974" />
                </g>
              </g>
            </g>
          </svg>
        </span>
      </a>
    </div>
  </section>

</div>

  )
}

export default TransportBannner
