/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";

const Header = () => {
  const [showHeader, setShowHeader] = useState(false);
  const [MobileNav, setMobileNav] = useState(false);

  const spanish = useRef();

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }
  };

  const changeLanguage = (lang) => {
    const translateElement = document.getElementById(
      "google_translate_element"
    );
    console.log(typeof translateElement.querySelector, lang, "casdasdasda");
    if (
      translateElement &&
      typeof translateElement.querySelector === "function"
    ) {
      const select = translateElement.querySelector(".goog-te-combo");
      console.log(select, "casdasdasda");
      if (select) {
        select.value = lang;
        select.dispatchEvent(new Event("change"));
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (spanish.current) {
        spanish.current.click();
      }
    }, 1500);
  }, []);
  return (
    <>
      <section
        class={`home header-transparent header-sticky header-v1 hide-topbar-mobile${
          MobileNav ? " display-mobile-menu" : ""
        }`}
      >
        {/* <!-- topbar --> */}
        <div
          id="fh-header-minimized"
          className={`fh-header-minimized fh-header-v1 ${
            showHeader ? " minimized" : ""
          }`}
        ></div>

        <header
          id="masthead"
          className={`site-header clearfix ${showHeader ? " minimized" : ""}`}
        >
          <button className="d-none" onClick={() => changeLanguage("en")}>English</button>
          <button ref={spanish} className="d-none" onClick={() => changeLanguage("es")}>
            Spanish
          </button>
          <div className="header-main clearfix">
            <div class="container mobile_relative">
              <div class="row" style={{ position: "relative" }}>
                  
                <div class="site-logo col-md-3 col-sm-6 col-xs-6">
                  <a href="/" class="logo">
                    <img
                      src="./assets/images/logonew.png"
                      alt="Transport-360"
                      className="logo-light show-logo"
                    />
                    <img
                      src="./assets/images/logonew.png"
                      alt="Transport-360"
                      class="logo-dark hide-logo"
                      width={"50%"}
                      height={"100px"}
                    />
                  </a>
                  <h1 class="site-title">
                    <a href="#">Transport-360</a>
                  </h1>
                </div>
                <div class="site-menu col-md-9 col-sm-6 col-xs-6">
                  <nav id="site-navigation" class="main-nav primary-nav nav">
                    <ul class="menu">
                      <li class="active">
                        <a href="/">Start</a>
                      </li>
                      <li class="has-children">
                        <a href="/serviceaccord" class="dropdown-toggle">
                          Services
                        </a>
                        <ul class="sub-menu">
                          <li>
                            <a href="/micorconsulting">Microconsulting</a>
                          </li>
                          <li>
                            <a href="/Job">Jobs</a>
                          </li>
                        </ul>
                      </li>
                      <li class="has-children">
                        <a href="/transport360" class="dropdown-toggle">
                          Transport360 everBlue
                        </a>
                        <ul class="sub-menu">
                          <li>
                            <a href="/userguide">User Guide</a>
                            <ul class="sub-menu">
                              <li>
                                <a href="/keyconcept">Key Cocenpt</a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="/CalculateCompensate">
                              Calculate Reduce and Compensete
                            </a>
                          </li>
                          <li>
                            <a href="/Tools">
                              Tools,cirtification and Assosiation
                            </a>
                          </li>
                        </ul>
                      </li>

                      {/* <li>
                        <a href="/about">About Us</a>
                      </li> */}
                      <li>
                        <a href="/faq">NEWS</a>
                      </li>
                      <li>
                        <a href="/contact">Contact</a>
                      </li>
                      <li>
                        <a href="/Equip">Equipment</a>
                      </li>
                      {/* <li class="extra-menu-item menu-item-button-link">
                        <a href="/form" class="fh-btn btn">
                          Get A Quote
                        </a>
                      </li> */}
                      <li>
                        <a href="/form">simulator</a>
                      </li>
                    </ul>
                  </nav>
                </div>

                <a
                  href="#"
                  className={`navbar-toggle ${
                    MobileNav ? " selected-mobile" : ""
                  }`}
                  onClick={() => setMobileNav(true)}
                >
                  <span class="navbar-icon">
                    <span class="navbars-line"></span>
                  </span>
                </a>
              </div>
            </div>
          </div>
                    <div id="google_translate_element"></div>
        </header>
        {/* <!--primary-mobile-nav--> */}
        <div
          class="primary-mobile-nav header-v1"
          id="primary-mobile-nav"
          role="navigation"
        >
          <a
            href="#"
            class="close-canvas-mobile-panel"
            onClick={() => setMobileNav(false)}
          >
            ×
          </a>
          <ul class="menu">
            <li class="current-menu-item menu-item-has-children">
              <a href="/">Start</a>
            </li>
            <li class="has-children">
              <a href="/services" class="dropdown-toggle">
                Services
              </a>
              <ul class="sub-menu">
                <li>
                  <a href="/services">All Services</a>
                </li>
                <li>
                  <a href="road-freight-forwarding.html">
                    Road Freight Forwarding
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <a href="/about">About Us</a>
            </li>
            <li>
              <a href="/faq">FAQS</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
            <li class="extra-menu-item menu-item-button-link">
              <a href="request-a-quote.html" class="fh-btn btn">
                Get A Quote
              </a>
            </li>
          </ul>
        </div>
        <div id="off-canvas-layer" class="off-canvas-layer"></div>
        {/* <!--primary-mobile-nav end--> */}
      </section>
    </>
  );
};

export default Header;
