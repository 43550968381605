/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";

const Section4 = () => {
  return (
    <>
      {/* <!-- about sec--> */}
      <section class="aboutsec-3 secpadd">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="abotimglft">
                <img
                  src="./assets/images/resources/about2.jpg"
                  class="img-responsive"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="abotinforgt">
                <div class="fh-section-title f30 clearfix  text-left version-dark paddbtm30">
                  <h2>The Complete Solution</h2>
                </div>
                <p>
                  Our warehousing services are known nationwide to be one of the
                  most reliable, safe and affordable, because we take pride in
                  delivering the best of warehousing services, at the most
                  reasonable prices.
                </p>
                <p>
                  Pleasure and praising pain was born and I will give you a
                  complete account of system, and expound actual teachings
                  occasionally circumstances.
                </p>
                <a href="#" class="fh-button button  fh-btn margtop30">
                  More About Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- about end--> */}
    </>
  );
};

export default Section4;
