import React from 'react'
import TransportBannner from '../Component/Transport360/TransportBannner'
import girlimg from "../assets/mygirl.webp"

import Slide1 from "../assets/keyslide1.webp"
import Slide2 from "../assets/keyslide2.webp"
import Slide3 from "../assets/keyslid3.webp"

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard,Autoplay } from 'swiper/modules';

const KeyConcept = () => {
  return (
    <div>
         <TransportBannner backimg={girlimg}  header={"Key concepts"} />
         <div className="container">
<div className='py-5 mb-5'>
    <h1 style={{
        fontSize:"36px",
        color:"rgb(9, 122, 241)"
    }}>What do you have to know </h1>
</div>
<Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}        
          loop={true}
        keyboard={true}
        modules={[Navigation,Autoplay, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
        slidesPerView={1}
        style={{
            marginBottom:"4rem"
        }}
      >
        <SwiperSlide >
            <img src={Slide1} alt="" srcset="" />
        </SwiperSlide>
        <SwiperSlide>          
            <div className="container">
                <img src={Slide2} alt="" srcset="" />
                </div>  
</SwiperSlide>
        <SwiperSlide>    
              <div className="container">  
            <img src={Slide3} alt="" srcset="" />
            </div> 

</SwiperSlide>
    

      </Swiper>
</div>
    </div>
  )
}

export default KeyConcept
