/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const ContactDetails = () => {
  return (
    <>
      {/* <!--contact pagesec--> */}
      <section class="contactpagesec secpadd">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div class="fh-section-title clearfix f25 text-left version-dark paddbtm40">
                <h2>Contact Details</h2>
              </div>
              <p class="margbtm30">
                If you have any questions about what we offer for consumers or
                for business, you can always email us or call us via the below
                details. We’ll reply within 24 hours.
              </p>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <div class="fh-contact-box type-address ">
                    <i class="flaticon-pin"></i>
                    <h4 class="box-title">Visit our office</h4>
                    <div class="desc">
                      <p>
                        244, Transport-360, Los Angeles, California City, United
                        States – 90005{" "}
                      </p>
                    </div>
                  </div>
                  <div class="fh-contact-box type-email ">
                    <i class="flaticon-business"></i>
                    <h4 class="box-title">Mail Us at</h4>
                    <div class="desc">
                      <p>
                        Mailus@Transport-360.com
                        <br /> Wesupportyou@Transport-360.com
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="fh-contact-box type-phone ">
                    <i class="flaticon-phone-call "></i>
                    <h4 class="box-title">Call us on</h4>
                    <div class="desc">
                      <p>
                        Office: +44 567 890123 &amp; +44 123 456789
                        <br /> Customer Care: 1800-123-45-6789
                      </p>
                    </div>
                  </div>
                  <div class="fh-contact-box type-social ">
                    <i class="flaticon-share"></i>
                    <h4 class="box-title">We are social</h4>
                    <ul class="clearfix">
                      <li class="facebook">
                        <a href="#" target="_blank">
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li class="twitter">
                        <a href="#" target="_blank">
                          <i class="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li class="googleplus">
                        <a href="#" target="_blank">
                          <i class="fa fa-google-plus"></i>
                        </a>
                      </li>
                      <li class="pinterest">
                        <a href="#" target="_blank">
                          <i class="fa fa-pinterest"></i>
                        </a>
                      </li>
                      <li class="linkedin">
                        <a href="#" target="_blank">
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="opening-hours vc_opening-hours">
                <h3>WORKING HOURS</h3>
                <p>
                  Pleasure and praising pain was born and will give you a
                  complete happiness.
                </p>
                <ul>
                  <li>
                    Monday <span class="hour">9:00 am – 17.00 pm</span>
                  </li>
                  <li>
                    Tuesday<span class="hour">9:00 am – 18.00 pm</span>
                  </li>
                  <li>
                    Wednesday <span class="hour">9:00 am – 18.00 pm</span>
                  </li>
                  <li>
                    Thurs &amp; Friday
                    <span class="hour">10:00 am – 16.00 pm</span>
                  </li>
                  <li>
                    Sat &amp; Sunday <span class="hour main-color">Closed</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--contact end--> */}
    </>
  );
};

export default ContactDetails;
