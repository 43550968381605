/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import myimg from "../assets/df80c94b-1bd7-460e-831a-14c4b06e2436.webp"
import myimg1 from "../assets/162e6567-fe35-4923-bcf8-daafd18d1682.webp"
import myimg2 from "../assets/731e5273-8253-4aed-ad93-81c875c74141.webp"
const Welcome = () => {
  return (
    <>
      {/* <!-- Welcome sec --> */}
      <div class="welcomesec secpadd">
        <div class="container">
          <div class="fh-section-title clearfix  text-center version-dark paddbtm20">
            <h2>
              Welcome to Transport-360 <span class="main-color">Hub</span>
            </h2>
          </div>
          <h4 class="haeadingpara text-center paddbtm40">
            Transport-360 is more than logistics.
            <br /> We can also optimize your packaging, manage your materials
            sourcing, and so much more.
          </h4>

          <div class="welservices row">
            <div class="col-md-4 col-sm-6">
              <div class="fh-icon-box icon-type-theme_icon style-1 version-dark hide-button icon-left">
                <img src={myimg} alt="asa"/>
                {/* <span class="fh-icon">
                  <i class="flaticon-transport-3"></i>
                </span>
                <h4 class="box-title">
                  <a href="#">Air Freight Forwarding</a>
                </h4>
                <div class="desc">
                  <p>
                    As a leader in global air freight forwarding, OIA Global
                    excels in providing tailored transportation
                  </p>
                </div> */}
                   <h4 class="box-title">
                  <a  href="/transport360" target="_blank">TRANSPORT360: EverBlue</a>
                </h4>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="fh-icon-box icon-type-theme_icon style-1 version-dark hide-button icon-left">
              <img src={myimg1} alt="asa"/>

                {/* <span class="fh-icon">
                  <i class="flaticon-transport-4"></i>
                </span> */}
                <h4 class="box-title">
                  <a href="/" >Employment Portal</a>
                </h4>
                {/* <div class="desc">
                  <p>
                    Ocean Freight plays perhaps the most vital role in most
                    transportation and supply chain solutions.
                  </p>
                </div> */}
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="fh-icon-box icon-type-theme_icon style-1 version-dark hide-button icon-left">
                {/* <span class="fh-icon">
                  <i class="flaticon-transport-5"></i>
                </span> */}
                              <img src={myimg2} alt="asa"/>

                <h4 class="box-title">
                  <a href="/faq"  target="_blank">Our Newsletter</a>
                </h4>
                {/* <div class="desc">
                  <p>
                    Cargo are transported at some stage of their journey along
                    the world’s roads where we give you a reassuring presence.
                  </p>
                </div> */}
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="fh-icon-box icon-type-theme_icon style-1 version-dark hide-button icon-left">
                {/* <span class="fh-icon">
                  <i class="flaticon-transport-5"></i>
                </span> */}
                              <img src={myimg2} alt="asa"/>

                <h4 class="box-title">
                  <a href="/Job"  target="_blank">Our Job</a>
                </h4>
                {/* <div class="desc">
                  <p>
                    Cargo are transported at some stage of their journey along
                    the world’s roads where we give you a reassuring presence.
                  </p>
                </div> */}
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="fh-icon-box icon-type-theme_icon style-1 version-dark hide-button icon-left">
                {/* <span class="fh-icon">
                  <i class="flaticon-transport-5"></i>
                </span> */}
                              <img src={myimg2} alt="asa"/>

                <h4 class="box-title">
                  <a href="/micorconsulting"  target="_blank">Our Consultency</a>
                </h4>
                {/* <div class="desc">
                  <p>
                    Cargo are transported at some stage of their journey along
                    the world’s roads where we give you a reassuring presence.
                  </p>
                </div> */}
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="fh-icon-box icon-type-theme_icon style-1 version-dark hide-button icon-left">
                {/* <span class="fh-icon">
                  <i class="flaticon-transport-5"></i>
                </span> */}
                              <img src={myimg2} alt="asa"/>

                <h4 class="box-title">
                  <a href="/form"  target="_blank">Our Simulator</a>
                </h4>
                {/* <div class="desc">
                  <p>
                    Cargo are transported at some stage of their journey along
                    the world’s roads where we give you a reassuring presence.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Welcome sec   end--> */}
    </>
  );
};

export default Welcome;
