import React from 'react'
import Pagetitle from '../Component/Pagetitle'
import { Accordion } from 'react-bootstrap'

const ServiceAccord = () => {
  return (
    <div>
       <Pagetitle title="User's guide"/>
 <div className="container pt-5">
        <div className="row">
            <div className="custum-container" style={{
                maxWidth:"70%",
                margin:"0 auto",
                height:"500px"
            }}>
  <Accordion defaultActiveKey="0">
      <Accordion.Item  className='item-accordin' eventKey="0">
        <Accordion.Header> <h1>Strategic Consulting </h1></Accordion.Header>
        <Accordion.Body>
       
     
            <p className='p-styleaccord' >We all have a passion. This is mine! From a very early age, I decided to master my art. And I've been learning ever since.
             </p>
           
  
             <button class="btn btn-primary" type="submit">Button</button>

           
     
   
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item  className='item-accordin' eventKey="1">
        <Accordion.Header><h1>Our motivation</h1> </Accordion.Header>
        <Accordion.Body>
   
      <p> Our work is our goal and the force that drives us. It's the reason we get up every day and why we dedicate ourselves to this. Most importantly, however, it encourages us to achieve bigger and better things with each new challenge that presents itself.  </p> 
      <button class="btn btn-primary" type="submit">Button</button>
 </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item  className='item-accordin' eventKey="2">
        <Accordion.Header><h1>Our mission</h1> </Accordion.Header>
        <Accordion.Body>
   <p>It's easy to do great work if you really believe in what you do. For this reason we work every day to help people like you.</p>
   <button class="btn btn-primary" type="submit">Button</button>
 
          </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            </div>
        </div>
      </div>
    </div>

  )
}

export default ServiceAccord
