/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Pagetitle from '../Pagetitle'
import {BiSearchAlt2} from  "react-icons/bi"
import {FcBriefcase} from "react-icons/fc"
import {FcViewDetails} from "react-icons/fc"
import img1 from "./../../assets/162e6567-fe35-4923-bcf8-daafd18d1682.webp"
import img2 from "./../../assets/e2ebc347-854e-47cc-9a90-aaebd77e0f9f.webp"
import img3 from "./../../assets/8f72c4bb-d0f8-4813-8cf3-f927c523f6b0.webp"
import img4 from "./../../assets/c4e81fa2-db39-4447-816c-92a91fd6211b.webp"
import img5 from "./../../assets/ccf7360b-7209-48cd-8e56-39a9031c30a3.webp"
// import { Link } from 'react-router-dom'
const Jobs = () => {
  const openUrlInNewTab = (url) => {
    window.open(url, '_blank');
  };
  return (
    <div>
         <Pagetitle title="Employment"/>
         <div class="welcomesec secpadd">
        <div class="container">
          <div class="fh-section-title clearfix  text-center version-dark paddbtm20">
            <h2>
              Welcome to Our <span class="main-color">Job Section</span>
            </h2>
          </div>
          <h4 class="haeadingpara text-center paddbtm40">
            Transport-360 is more than logistics.
            <br /> We can also optimize your packaging, manage your materials
            sourcing, and so much more.
          </h4>
          
     
          
   
          
     
            <div class="col-md-4 col-sm-6">
              <div class="fh-icon-box icon-type-theme_icon style-1 version-dark hide-button icon-left">
             
                <span class="fh-icon">
                <BiSearchAlt2/>                </span>
                <h4 class="box-title">
                  <a href=''>Looking for an job</a>
                </h4>
                <div class="desc">
                  <p>
                  Are you looking for a job? Now you will find the best offers in our publications.
                  </p>
                </div>
                  
      </div>
      
      </div>
     
            <div class="col-md-4 col-sm-6">
              <div class="fh-icon-box icon-type-theme_icon style-1 version-dark hide-button icon-left">
             
                <span class="fh-icon">
                <FcBriefcase/>                </span>
                <h4 class="box-title">
                  <a href="#">Looking for an job</a>
                </h4>
                <div class="desc">
                  <p>
                  Hiring talent? We will make your search more efficient.
                  </p>
                </div>
                  
      </div>
      
      </div>
            <div class="col-md-4 col-sm-6">
              <div class="fh-icon-box icon-type-theme_icon style-1 version-dark hide-button icon-left">
             
                <span class="fh-icon">
                <FcViewDetails/>                </span>
                <h4 class="box-title">
                  <a href="#">Help Writing your CV</a>
                </h4>
                <div class="desc">
                  <p>
                  Do you need help writing your CV? We will help you shape it
                  </p>
                </div>
                  
      </div>
      
      </div>
            </div>
    </div>
    <div className="container py-5">
        <div className="row gy-5">
        <div className="col-md-3">
      <div onClick={() => openUrlInNewTab('https://www.linkedin.com/feed/update/urn:li:activity:7088064788756406273/')}>
        <img src={img1} alt="" srcset="" />
      </div>
    </div>
    <div className="col-md-3">
      <div onClick={() => openUrlInNewTab('https://www.randstad.es/candidatos/ofertas-empleo/oferta/responsable-trafico-terrestre-madrid-madrid-2715802/')}>
        <img src={img2} alt="" srcset="" />
      </div>
    </div>
    <div className="col-md-3">
      <div onClick={() => openUrlInNewTab('https://lnkd.in/dmQpVeHS')}>
        <img src={img3} alt="" srcset="" />
      </div>
    </div>
    <div className="col-md-3">
      <div onClick={() => openUrlInNewTab('https://lnkd.in/dKbxQPs5')}>
        <img src={img4} alt="" srcset="" />
      </div>
    </div>
    <div className="col-md-3">
      <div onClick={() => openUrlInNewTab('https://www.randstad.es/candidatos/ofertas-empleo/oferta/site-lead-alcala-de-henares-madrid-2711348/')}>
        <img src={img5} alt="" srcset="" />
      </div>
    </div>
        
           
        </div>
    </div>
    </div>
  )
}

export default Jobs
