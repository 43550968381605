import React from 'react'
import TransportBannner from '../Component/Transport360/TransportBannner'
import girlimg from "./../assets/girl.webp"
import SectionHeader from '../Component/Transport360/SectionHeader'
import DynamicSlider from '../Component/Transport360/DynamicSlider'
import slide1 from "../assets/compenslide14.webp"
import slide2 from "../assets/compenslide15.jpg"
import slide3 from "../assets/compenslide16.webp"
import slide4 from "../assets/compenslide17.webp"
import slide5 from "../assets/compenslide18.webp"
import slide6 from "../assets/compenslide19.webp"   
import slide7 from "../assets/compenslide20.webp"
import slide8 from "../assets/compenslide21.jpg"
import slide9 from "../assets/compenslide22.webp"
import slide10 from "../assets/compenslide23.webp"
import slide11 from "../assets/compenslide24.webp"
import slide12 from "../assets/compenslide25.webp"
const CirtificationAndAssosiation = () => {
  return (
    <div>
   <TransportBannner backimg={girlimg}  header={"Tools, Certifications and Associations"} />
       <SectionHeader head={"Complementary Tools and Guides"}/>
  <div className='py-5'>
        <DynamicSlider slide1={slide1} slide2={slide2} slide3={slide3} slide4={slide4} slide5={slide5} 
        link1={"https://aclima.eus/el-reglamento-de-la-union-europea-sobre-el-marco-para-las-inversiones-financieras-sostenibles-reglamento-de-taxonomia/"}
        link2={"https://aplanet.org/es/"}
        link3={"https://observatoriorsc.org/la-rsc-que-es/"}
        link4={"https://www.garrigues.com/es_ES/noticia/publicada-la-ley-en-materia-de-informacion-no-financiera-y-diversidad-en-espana"}
        link5={"https://www.globalreporting.org/"}
        head1={"The European Union Regulation on the framework for sustainable financial investments (“Taxonomy Regulation”) "} para1={"The Taxonomy Law is a regulation that establishes the criteria to determine whether an economic activity is considered environmentally sustainable for the purposes of establishing the degree of environmental sustainability of an investment. In other words, the taxonomy law is a tool to identify which economic activities are sustainable and which are not.The following link is the official regulation: EUR-Lex - 4481971 - EN - EUR-Lex (europa.eu) If you click on the image you will find a post explaining the regulations! "}
        head2={"APlanet"} para2={" APlanet is a company that offers software solutions for the management and analysis of ESG (environmental, social and governance) data of organizations. Its objective is to help companies integrate sustainability into their operations and maximize their positive impact on their stakeholders. "}
        head3={"Corporate Social Responsibility"} para3={" Corporate Social Responsibility (CSR) is a way of running companies based on managing the impacts that their activity generates on their clients, employees, shareholders, local communities, the environment and society in general.  "}
        head4={"Non-Financial Information Statement "} para4={"   Law 11/2018 on Non-Financial Information and Diversity in Spain establishes the obligation for companies that meet certain requirements to include in their management report or in a separate document subject to the same approval criteria information on their social, environmental and economic impact. on human rights, among other aspects. The following link is the official regulation: BOE-A-2018-17989 Law 11/2018, of December 28, which modifies the Commercial Code, the consolidated text of the Capital Companies Law approved by the Royal Decree Legislative 1/2010, of July 2, and Law 22/2015, of July 20, on Account Audit, on non-financial information and diversity.  If you click on the image you will find a post explaining the regulations!  "}
        head5={" Global Reporting Initiative (GRI) "} para5={" It is an entity that provides a standard guide to unify Non-Financial Information and present it according to criteria widely accepted by institutions and organizations around the world. There are other reporting systems, but this is the one most used in the EINF.  "}
        />
      </div>
      <div className='py-5' style={{
        marginTop:"5rem",
        marginBottom:"5rem"
      }}>
         <SectionHeader head={"Recognized certifications"}/>
</div>
      <DynamicSlider slide1={slide6} slide2={slide7} slide3={slide8} slide4={slide9} 
              link1={"https://www.iso.org/iso-14001-environmental-management.html"}
              link2={"https://www.aenor.com/certificacion/medio-ambiente"}
              link3={"https://www.lean-green.eu/"}
              link4={"https://www.iso.org/search.html?q=14064"}
      
        head1={"Global Environmental Management: ISO 14001 Environmental Management"} para1={" The implementation of an Environmental Management System according to the international standard ISO 14001 makes it possible to systematize environmental aspects and promote environmental protection and pollution prevention. This also makes it possible to optimize the management of resources and waste, reduce negative environmental impacts, reduce management costs, eliminate barriers to export, reduce the risk of litigation and sanctions, have greater access to subsidies and other preferential financing lines, and reduce occupational risks motivating staff.  "}
        head2={"AENOR"} para2={" AENOR offers the largest catalog of certificates, validations and environmental verifications, which allow an organization, in the activities it provides or the products it develops or markets, to accredit its commitment to the environment, minimizing impacts and favoring the best management of resources. The recognitions issued by AENOR help ensure that environmental commitment is also a factor of competitive differentiation."}
        head3={"Lean and Green"} para3={"  Lean & Green is the largest European collaboration platform specifically aimed at reducing emissions associated with the supply chain. This is an international initiative whose objective is to help companies in all sectors reduce their greenhouse gas emissions derived from logistics activities to achieve the objectives defined at the Climate Summit in Paris (COP21) of neutral emissions in carbon by 2050 "}
        head4={"ISO 14064"} para4={" This standard defines the limits for calculating and reporting a company's carbon footprint and facilitates the inclusion of indirect GHG (Greenhouse Gas) emission sources. "}
     
        />
 <div className='py-5' style={{
        marginTop:"5rem",
        marginBottom:"5rem"
      }}>
         <SectionHeader head={"Associations you can join!"}/>
</div>
      <DynamicSlider slide1={slide10} slide2={slide11} slide3={slide12}  
      link1={"http://sostenibilidadyprogreso.org/"}
      link2={"https://www.asociacionhuelladecarbono.org/en/"}
      link3={"https://www.pactomundial.org/"}
        head1={"Association for Sustainability and Progress of Societies (ASYPS)"} para1={" The ASYPS emerges as an active Platform, from organized civil society, with the objective of promoting responses and solutions to Global Change and favoring the socio-ecological transition towards new paradigms of sustainable progress.  "}
        head2={"The Carbon Footprint Association"} para2={"  The Carbon Footprint Association - NGO Climate Change, focuses specifically on individual citizens. This organization offers free, effective and easy measures to improve your personal/business carbon footprint "}
        head3={"Global Compact Spanish Network"} para3={" United Nations Global Compact and offers resources, tools and news related to sustainability and the environment. It provides information on the Sustainable Development Goals, practical guides, success stories, events and a network of contacts to promote collaboration on environmental issues.   "}

        /> 

      </div>

  )
}

export default CirtificationAndAssosiation
