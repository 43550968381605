import React from "react";
import { BrowserRouter,Routes, Route } from "react-router-dom";
import Header from "./Layout/Header";
// import Footer from "./Layout/Footer";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Services";
import Faqs from "./Pages/Faqs";
import Contact from "./Pages/Contact";
import RequestToQuote from "./Pages/RequestToQuote";
import MicroConsulting from "./Component/Services/MicroConsulting";
import Jobs from "./Component/Services/Jobs";
import Transport360 from "./Pages/Transport360";
import CalculateCompensate from "./Pages/CalculateCompensate";
import CirtificationAndAssosiation from "./Pages/CirtificationAndAssosiation";
import UserGuide from "./Pages/UserGuide";
import KeyConcept from "./Pages/KeyConcept";
import Equipment from "./Pages/Equipment";
import ServiceAccord from "./Pages/ServiceAccord";

function App() {
  return (
   <>
   <Header />
   <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Home />}/>
      <Route exact path="/about" element={<About />}/>
      <Route exact path="/services" element={<Services />}/>
      <Route exact path="/micorconsulting" element={<MicroConsulting />}/>
      <Route exact path="/transport360" element={<Transport360 />}/>
      <Route exact path="/CalculateCompensate" element={<CalculateCompensate />}/>
      <Route exact path="/userguide" element={<UserGuide />}/>
      <Route exact path="/keyconcept" element={<KeyConcept />}/>
      <Route exact path="/Tools" element={<CirtificationAndAssosiation />}/>
      <Route exact path="/Job" element={<Jobs />}/>
      <Route exact path="/Equip" element={<Equipment />}/>
      <Route exact path="/faq" element={<Faqs />}/>
      <Route exact path="/contact" element={<Contact />}/>
      <Route exact path="/form" element={<RequestToQuote />}/>
      <Route exact path="/serviceaccord" element={<ServiceAccord />}/>
    </Routes>
   </BrowserRouter>
   {/* <Footer /> */}
   </>
  );
}

export default App;
