import React from 'react'
import Pagetitle from '../Component/Pagetitle'
import Sectionser from '../Component/Services/Sectionser'
import Sercard from '../Component/Services/Sercard'
import Section3 from '../Component/Services/Section3'
import Section4 from '../Component/Services/Section4'


const Services = () => {
  return (
    <>
    <Pagetitle title="Services"/>
    <Sectionser />
    <Sercard/>
    <Section3/>
    <Section4/>
    
    </>
  )
}

export default Services