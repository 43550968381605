import React from "react";

const Message = () => {
  return (
    <>
      {/* <!--contact form --> */}
      <section class="contactpagform graybg secpadd">
        <div class="container">
          <div class="fh-section-title clearfix f25 text-center version-dark paddbtm40">
            <h2>Leave Your Message</h2>
          </div>
          <p class="paddbtm40 text-center">
            If you have any questions about the services we provide simply use
            the form below. We try and respond to all
            <br />
            queries and comments within 24 hours.
          </p>
          <form
            method="post"
            action="http://st.ourhtmldemo.com/template/cargohub/ajax/mail.php"
            id="contact-form"
            novalidate="novalidate"
          >
            <div class="fh-form fh-form-3">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <p class="field">
                    <input name="name" placeholder="Your Name*" type="text" />
                  </p>
                  <p class="field">
                    <input
                      name="email"
                      placeholder="Email Address*"
                      type="email"
                    />
                  </p>
                  <p class="field">
                    <input name="phone" placeholder="Phone" type="text" />
                  </p>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <p class="field single-field">
                    <textarea
                      name="message"
                      cols="40"
                      rows="10"
                      placeholder="Your Message..."
                    ></textarea>
                  </p>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <p class="field submit">
                    <button class="fh-btn" type="submit">
                      Submit
                    </button>
                  </p>
                </div>
                <div id="loading" style={{display:'none'}}>
                  <img src="images/ajax-loader.png" alt="loading" />
                </div>
                <div class="contact-form-message"></div>
              </div>
            </div>
          </form>
        </div>
      </section>
      {/* <!--contact form  end --> */}
    </>
  );
};

export default Message;
