import React from 'react'
import Pagetitle from '../Pagetitle'
import { Accordion } from 'react-bootstrap'

const MicroConsulting = () => {
  return (
    <div>
           <Pagetitle title="micro consulting"/>
      <div className="container pt-5">
        <div className="row">
            <div className="custum-container" style={{
                maxWidth:"70%",
                margin:"0 auto",
                height:"500px"
            }}>
  <Accordion defaultActiveKey="0">
      <Accordion.Item  className='item-accordin' eventKey="0">
        <Accordion.Header> <h1>Personalized advice </h1></Accordion.Header>
        <Accordion.Body>
            <ul>
     
                <li> <p className='p-styleaccord' >Don't worry if you don't have all the necessary data, our team of experts is here to guide you. 
             </p>
                </li>
    <br/>
    <li>
    <p className='p-styleaccord'>We offer personalized consulting for each client, providing tools capable of obtaining the data you need, even in situations where specific data is not available. </p>
</li>
</ul>
           
     
   
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item  className='item-accordin' eventKey="1">
        <Accordion.Header><h1>Industry Experience</h1> </Accordion.Header>
        <Accordion.Body>
   
       <ul>
     
     <li> <p className='p-styleaccord' >Our consultants have experience in various sectors, including transportation, where they have carried out sustainability and carbon footprint reduction projects.
  </p>
     </li>
<br/>
<li>
<p className='p-styleaccord'>Your business will benefit from our sector knowledge to improve its sustainability and energy efficiency. </p>
</li>
</ul>        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item  className='item-accordin' eventKey="2">
        <Accordion.Header><h1>Competitive advantage</h1> </Accordion.Header>
        <Accordion.Body>
   
       <ul>
     
     <li> <p className='p-styleaccord' >Compared to other services, what sets us apart is our personalized approach and the experience of our team.
  </p>
     </li>
<br/>
<li>
<p className='p-styleaccord'>With our help, you will not only be taking steps towards sustainability, but you will be improving your reputation and positioning in the market. </p>
</li>
</ul>        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            </div>
        </div>
      </div>
    </div>
  )
}

export default MicroConsulting
