import React from 'react'

const Pagetitle = ({title}) => {
  return (
    <>
    {/* <!--Page Header--> */}
        <div class="page-header title-area">
            <div class="header-title">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <h1 class="page-title">{title}</h1> </div>
                    </div>
                </div>
            </div>
           
        </div>
        {/* <!--Page Header end--> */}
    </>
  )
}

export default Pagetitle