/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable no-undef */
import React, { useState, useRef } from "react";
import Pagetitle from "../Component/Pagetitle";
import Section1 from "../Component/Request/Section1";
// import CargoRequest from "../Component/Request/CargoRequest";
import Form from "react-bootstrap/Form";
import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
  Marker,
  Polyline,
  PolylineF,
  MarkerF,
  DirectionsRenderer,
} from "@react-google-maps/api";
// import {  } from "google-maps-react";
import { Routes, RailywayRoutes } from "../Component/Routes/Routes";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import Loader from "../Component/Loader/Loader";

const mapStyles = {
  width: "100%",
  height: 500,
};

const RequestToQuote = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyB0FjvCBybYldOrdRoI6GkvurRvN5uF1PI",
    libraries: ["places"],
  });

  const [directionsService, setDirectionsService] = useState([]);
  // const [distance, setDistance] = useState("");
  // const [duration, setDuration] = useState("");
  const [MultiRoutes, setMultiRoutes] = useState();
  const [Origin_des, setOrigin_des] = useState([]);
  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();
  const [table, setTable] = useState(false);
  const [map, setMap] = useState(false);
  const [railmap, setRailMap] = useState(false);
  const [rail, setRail] = useState(false);
  const [datas, setDatas] = useState([]);
  const [Distance_duration, setDistance_duration] = useState([]);
  // const [All_Distance_duration, setAll_Distance_duration] = useState();
  const [SelectBox, setSelectBox] = useState("");
  const [Selectvalue, setSelectValue] = useState("");
  const [loader, setLoader] = useState(false);
  const [euro, setEuro] = useState();
  const [RoadKM, setRoadKM] = useState("");
  const [raileuro, setRailEuro] = useState();
  const [RaildestEuro, setRaildestEuro] = useState();
  const [RailKM, setRailKM] = useState();
  const [CalculateCost, setCalculateCost] = useState([]);
  const [singleCalculateCost, setSingleCalculateCost] = useState([]);
  const [polylinePaths, setPolylinePaths] = useState([]);
  const [Center, setCenter] = useState({ lat: 0, lng: 0 });

  /**@type React.MutableRefObject<HTMLInputElement> */
  const originref = useRef();

  /**@type React.MutableRefObject<HTMLInputElement> */
  const destinationref = useRef();

  if (!isLoaded) {
    return <h1>Loading.......</h1>;
  }

  // calculate singal route function
  const calculateRoute = async (origins, destinations, data) => {
    setLoader(true);
    console.log(data, "data");

    const directionsService = new google.maps.DirectionsService();

    const multiRoutes = [];
    const allResponses = [];

    for (let index = 0; index < data.length; index++) {
      const result = await directionsService.route({
        origin: data[index].origin,
        destination: data[index].destination,
        travelMode: google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
      });
      multiRoutes.push(result);
    }

    const firstRouteInEachCombination = multiRoutes.map(
      (route) => route.routes[0].legs[0]
    );
    setMultiRoutes(firstRouteInEachCombination);
    setTimeout(() => {
      setLoader(false);
      // setDistance_duration(firstRouteInEachCombination); //this is for distance and duration
    }, 3000);
    // setDirectionsService(multiRoutes);

    const startL1lat = firstRouteInEachCombination[0]?.start_location.lat();
    const startL1lng = firstRouteInEachCombination[0]?.start_location.lng();
    const endL1lat = firstRouteInEachCombination[0]?.end_location.lat();
    const endL1lng = firstRouteInEachCombination[0]?.end_location.lng();
    const endL2lat = firstRouteInEachCombination[2]?.end_location.lat();
    const endL2lng = firstRouteInEachCombination[2]?.end_location.lng();
    const startL2lat = firstRouteInEachCombination[2]?.start_location.lat();
    const startL2lng = firstRouteInEachCombination[2]?.start_location.lng();

    const originlatlng = [
      { lat: startL1lat, lng: startL1lng }, // Point A
      { lat: endL1lat, lng: endL1lng }, // Point B
      { lat: startL2lat, lng: startL2lng }, // Point C
    ];
    const destinationlatlng = [
      { lat: endL1lat, lng: endL1lng }, // Point A
      { lat: startL2lat, lng: startL2lng }, // Point B
      { lat: endL2lat, lng: endL2lng }, // Point C
    ];

    setOrigin(originlatlng);
    setDestination(destinationlatlng);

    const newPolylinePaths = [];

    const polylinePoints = [
      originlatlng[1], // Start point of polyline
      destinationlatlng[1], // End point of polyline
    ];

    newPolylinePaths.push(polylinePoints);

    // Calculate and display distance and duration
    for (let i = 0; i < originlatlng.length; i++) {
      const directionsServiceforPolyLine = new google.maps.DirectionsService();
      const request = {
        origin: originlatlng[i],
        destination: destinationlatlng[i],
        travelMode: google.maps.TravelMode.DRIVING,
      };

      setCenter({ lat: polylinePoints[0].lat, lng: polylinePoints[0].lng });

      const alllegs = [];
      directionsServiceforPolyLine.route(request, function (response, status) {
        if (status === "OK") {
          allResponses.push(response);
          // const legs = response.routes[0].legs;
          // const totalDistance = legs[0].distance.text;
          // const totalDuration = legs[0].duration.text;

          // setDistance(totalDistance);
          // setDuration(totalDuration);

          // console.log("Route", i + 1);
          // console.log("Total Distance:", totalDistance);
          // console.log("Total Duration:", totalDuration);
          // console.log("response:", response);
          // alllegs.push(totalDistance)

          setDistance_duration(allResponses);
          // Iterate through the array and calculate the new distance
          const multipliedDistances = allResponses.map((obj) => {
            const route = obj.routes[0];
            if (
              route &&
              route.legs &&
              route.legs[0] &&
              route.legs[0].distance
            ) {
              // Extract the distance text and convert it to a number (remove ' km' and replace any commas)
              const distanceText = route.legs[0].distance.text;
              const numericDistance = parseFloat(
                distanceText.replace(" km", "").replace(/,/g, "")
              );

              // Multiply the numeric distance by 1.25
              const newNumericDistance = numericDistance;

              // Return a new object with the updated distance
              return {
                ...obj,
                routes: [
                  {
                    ...route,
                    legs: [
                      {
                        ...route.legs[0],
                        distance: {
                          ...route.legs[0].distance,
                          text: newNumericDistance.toFixed(2) + " km", // Convert back to text with 2 decimal places
                          value: newNumericDistance,
                        },
                      },
                    ],
                  },
                ],
              };
            }
            // If the structure doesn't match expectations, return the original object
            return obj;
          });
          console.log(multipliedDistances, "alllegs:");
          setCalculateCost(multipliedDistances);
        }

        console.log("alllegs:", allResponses);
      });
      // console.log(Distance_duration.map((e)=>e.routes.map((i)=>i.legs[0].distance.text.split(" "))),"alllegs")

      // console.log(lastrow, "alllegs:");
      console.log(polylinePoints, "alllegs:");
    }
    setPolylinePaths(newPolylinePaths);
    origin_destination();
  };
  console.log(Distance_duration, "Distance_duration");

  // console.log(polylinePaths, "tulla");

  // just calculate origin and destination function
  const origin_destination = async (data) => {
    // alert("hello");
    setLoader(true);
    console.log(data, "data");

    const directionsService = new google.maps.DirectionsService();

    const multiRoutes = [];

    const result = await directionsService.route({
      origin: originref.current.value,
      destination: destinationref.current.value,
      travelMode: google.maps.TravelMode.DRIVING,
      // provideRouteAlternatives: true,
    });
    multiRoutes.push(result);

    const firstRouteInEachCombination = multiRoutes.map(
      (route) => route.routes[0].legs[0]
    );
    setOrigin_des(firstRouteInEachCombination);
    // setMultiRoutes(firstRouteInEachCombination);
    setTimeout(() => {
      setLoader(false);
      // setDistance_duration(firstRouteInEachCombination); //this is for distance and duration
    }, 3000);
    setDirectionsService(multiRoutes);

    const multipliedDistances = multiRoutes.map((obj) => {
      const route = obj.routes[0];
      if (route && route.legs && route.legs[0] && route.legs[0].distance) {
        // Extract the distance text and convert it to a number (remove ' km' and replace any commas)
        const distanceText = route.legs[0].distance.text;
        const numericDistance = parseFloat(
          distanceText.replace(" km", "").replace(/,/g, "")
        );

        // Multiply the numeric distance by 1.25
        const newNumericDistance = numericDistance;

        // Return a new object with the updated distance
        return {
          ...obj,
          routes: [
            {
              ...route,
              legs: [
                {
                  ...route.legs[0],
                  distance: {
                    ...route.legs[0].distance,
                    text: newNumericDistance.toFixed(2) + " km", // Convert back to text with 2 decimal places
                    value: newNumericDistance,
                  },
                },
              ],
            },
          ],
        };
      }
      // If the structure doesn't match expectations, return the original object
      return obj;
    });
    setSingleCalculateCost(
      multipliedDistances.map((e) => e.routes[0].legs[0].distance.value)
    );
    console.log(multipliedDistances, "alllegs:");
  };

  // console.log(Origin_des.length, "table");

  // calculate all route function
  const calculateallRoute = async (allroutedata) => {
    console.log(allroutedata, "data");

    const directionsService = new google.maps.DirectionsService();

    const allroute_multiRoutes = [];

    for (let index = 0; index < allroutedata.length; index++) {
      const request = allroutedata[index].request;

      const routePromises = request?.map(async (e) => {
        setLoader(true);
        console.log(e, "routepromise");
        try {
          const result = await directionsService.route({
            origin: e.origin,
            destination: e.destination,
            travelMode: google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: true,
          });
          return result;
        } catch (error) {
          setLoader(false);
          console.log(error, "error");
          return null;
        }
      });
      const routeResults = await Promise.all(routePromises);

      allroute_multiRoutes.push(routeResults);
      setLoader(false);
    }

    // const breakarray = allroute_multiRoutes.flat(Infinity);
    // const firstRouteInEachCombination = breakarray?.map((e)=>e?.routes[0]?.legs[0]);

    setDirectionsService(allroute_multiRoutes);
    // setMultiRoutes(firstRouteInEachCombination);
    // setTimeout(() => {
    //   setAll_Distance_duration(firstRouteInEachCombination); //this is for distance and duration
    // }, 60000);
    // console.log(breakarray, "allRoutes");
    // console.log(firstRouteInEachCombination, "allRoutes");
    // setSelectedRoute(firstRouteInEachCombination); // for rendering on map
  };

  // console.log(directionsService, "allRoutes");

  const handleRouteSelect = (event) => {
    setRail(false);
    const selectedvalue = event.target.value;
    const selectedname = event.target.options[event.target.selectedIndex].text;

    setSelectBox(selectedname);
    setSelectValue(selectedvalue);

    if (selectedvalue === "all routes") {
      alert("all routes");
      const allroutebroke = Routes?.map((e) => e.value.split(","));
      const newData = [];
      for (let index = 0; index < allroutebroke.length; index++) {
        const allroutesdata = [
          {
            origin: originref.current.value,
            destination: allroutebroke[index][0],
          },
          {
            origin: allroutebroke[index][0],
            destination: allroutebroke[index][1],
          },
          {
            origin: allroutebroke[index][1],
            destination: destinationref.current.value,
          },
        ];

        newData.push({ request: allroutesdata });
      }
      // console.log(newData);
      calculateallRoute(newData);

      // const brokeroute = mydest.map((e,i)=>({origin:e[0],destination:newData[i+1][1]}));

      // const brokeroute = mydest.map((e, i) => {
      //   const origin = e[0];
      //   const destination = newData[i + 1] && newData[i + 1][0] ? newData[i + 1][0] : 'N/A';

      //   return { origin, destination };
      // });

      // console.log(brokeroute, "allroutesdata");
    } else if (
      selectedvalue === "Le Boulou , Bettembourg" ||
      selectedvalue === "Le Boulou , Calais"
    ) {
      // alert("railway");
      const broke = selectedvalue.split(",");

      const data = [
        {
          origin: originref.current.value,
          destination: broke[0],
        },
        {
          origin: broke[0],
          destination: broke[1],
        },
        {
          origin: broke[1],
          destination: destinationref.current.value,
        },
      ];

      console.log(data, "table");
      console.log(selectedvalue, "table");
      setDatas(data);
      // setRail(true);
      setTable(false);
      setRail(false);
      // setMap(false);
      calculateRoute(origin, destination, data);
    } else {
      // alert("road");
      const broke = selectedvalue.split(",");

      const data = [
        {
          origin: originref.current.value,
          destination: broke[0],
        },
        {
          origin: broke[0],
          destination: broke[1],
        },
        {
          origin: broke[1],
          destination: destinationref.current.value,
        },
      ];

      console.log(data, "table");
      console.log(selectedvalue, "table");
      setDatas(data);
      setTable(false);
      setRail(false);
      calculateRoute(origin, destination, data);
    }
    // const origins = [originref.current.value, broke[0]];
    // const destinations = [broke[0], destinationref.current.value];

    // setOrigin(origins);
    // setDestination(destinations);

    // console.log(broke, "i am broke");
    // console.log(origins, "i am origins");
    // setSelectedRoute(MultiRoutes[selectedvalue]);
    // setDistance(MultiRoutes[selectedvalue].legs[0].distance.text);
    // setDuration(MultiRoutes[selectedvalue].legs[0].duration.text);
  };

  // console.log(SelectBox, "cnknscscn");
  // console.log(directionsService, "directionsService");

  // calculator button function
  const handleTable = () => {
    // alert("cal");
    if (
      Selectvalue === "Le Boulou , Bettembourg" ||
      Selectvalue === "Le Boulou , Calais"
    ) {
      setRail(true);
      setRailMap(false);
      setTable(false);
      setMap(false);
      console.log(rail, "table");
    } else {
      setTable(true);
      setRail(false);
      setMap(false);
      setRailMap(false);
      console.log(table, "table");
    }
  };

  // map button function
  const handleMap = () => {
    // alert("cal");
    if (
      Selectvalue === "Le Boulou , Bettembourg" ||
      Selectvalue === "Le Boulou , Calais"
    ) {
      setRailMap(true);
      setMap(false);
      setRail(false);
      setTable(false);
      console.log(railmap, "table");
    } else {
      setMap(true);
      setRailMap(false);
      setRail(false);
      setTable(false);
      console.log(table, "table");
    }
  };
  const labels = ["A", "B", "C", "D"];

  // console.log(filteredRoutes,"cnknscscn");

  return (
    <>
      {loader ? (
        <div className="overlay">
          <Loader />
        </div>
      ) : null}
      <Pagetitle title="Simulador" />
      {/* <Section1 /> */}
      {/* <CargoRequest/> */}
      <section class="whychoose-1 secpadd" style={{ height: "auto" }}>
        <div class="container">
          <div class="row quote1forms mb-5">
            <div class="col-md-12">
              <form>
                <div class="fh-form request-form">
                  <div class="row">
                    <div class="col-md-8 request-row">
                      <div className="row">
                        <div class="field col-md-4">
                          <label>
                            Origin/From<span class="require">*</span>
                          </label>
                          <Autocomplete>
                            <input type="text" ref={originref} />
                          </Autocomplete>
                        </div>
                        <div class="field col-md-4">
                          <label>
                            Destination/To<span class="require">*</span>
                          </label>
                          <Autocomplete>
                            <input type="text" ref={destinationref} />
                          </Autocomplete>
                        </div>
                        <div class="field col-md-4">
                          <label>
                            Net tons of cargo
                            <span class="require">*</span>
                          </label>
                          <input
                            name="your-email"
                            placeholder="Net tons of cargo"
                            type="text"
                          />
                        </div>
                        <div className="col-md-12 mb-2">
                          <h4 className="mb-3 text-red">
                            Parameters to be set for the 'only road' transport
                            chain
                          </h4>
                        </div>
                        <p class="field col-md-6">
                          <label>Euros/Km</label>
                          <input
                            type="text"
                            placeholder="Euro/km"
                            onChange={(e) => setEuro(e.target.value)}
                          />
                        </p>
                        <p class="field col-md-6">
                          <label>Vel. Media Km/h /Average speed</label>
                          <input
                            type="text"
                            placeholder="Vel. Media Km/h /Average speed"
                            onChange={(e) => setRoadKM(e.target.value)}
                          />
                        </p>

                        <div className="col-md-12 mb-2">
                          <h4 className="mb-3 text-red">
                            Parameters to be set for the road leg of SSS chains
                            that use the TMCD
                          </h4>
                        </div>
                        <p class="field col-md-4">
                          <label>
                            Euros/Km Transport Origin/Origin hauling
                          </label>
                          <input
                            type="text"
                            placeholder="Euro/km"
                            onChange={(e) => setRailEuro(e.target.value)}
                          />
                        </p>
                        <p class="field col-md-4">
                          <label>Vel. Media Km/h /Average speed</label>
                          <input
                            type="text"
                            placeholder="Vel. Media Km/h"
                            onChange={(e) => setRailKM(e.target.value)}
                          />
                        </p>
                        <p class="field col-md-4">
                          <label>
                            Euros/Km Destination Hauling/Destination hauling
                          </label>
                          <input
                            type="text"
                            placeholder="Euros/Km"
                            onChange={(e) => setRaildestEuro(e.target.value)}
                          />
                        </p>
                        <div className="col-md-12 mb-2">
                          <h4 className="text-red">
                            Select which lines you want to consult
                          </h4>
                        </div>
                        <Form.Select size="lg" onChange={handleRouteSelect}>
                          <option selected>Select Line</option>
                          {/* <option value="all routes">TODAS</option> */}
                          <option value="recommanded">RECOMMANDADAS</option>
                          {Routes.map((e, index) => (
                            <>
                              <option key={index} value={e.value}>
                                {e.label}
                              </option>
                            </>
                          ))}
                        </Form.Select>
                        {/* for railway routes */}
                        <div className="row mt-5">
                          <div className="col-md-12 mb-2">
                            <h4 className="text-red">
                              Select which Railway lines you want to consult
                            </h4>
                          </div>
                          <div className="col-md-12">
                            <Form.Select size="lg" onChange={handleRouteSelect}>
                              <option selected>Select Railway Line</option>

                              {RailywayRoutes?.map((e, i) => (
                                <option value={e?.value} name={e?.name} key={i}>
                                  {e?.label}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>

                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            position: "relative",
                            top: "40px",
                          }}
                        >
                          <p class="field submit">
                            <button
                              class="fh-btn"
                              type="button"
                              onClick={() => {
                                handleTable();
                              }}
                            >
                              Calculator
                            </button>
                          </p>
                          <p class="field submit">
                            <button
                              class="fh-btn"
                              type="button"
                              onClick={() => {
                                handleMap();
                              }}
                            >
                              View Mapa Resultodas
                            </button>
                          </p>
                          <p class="field submit">
                            <button
                              class="fh-btn"
                              // onClick={()=>setIsopen(true)}
                            >
                              Nueva Simlutados
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <p class="field check-box">
                        <h5 className="text-red">
                          Additional options for the maritime leg of chains that
                          use the TMCD
                        </h5>
                        <span class="checkbox-box">
                          <span class="checkbox_item">
                            <label>
                              <input type="checkbox" />
                              Dangerous Goods
                            </label>
                          </span>
                          <span class="checkbox_item">
                            <label>
                              <input type="checkbox" />
                              Accompanied
                            </label>
                          </span>

                          <span class="checkbox_item">
                            <label>
                              <input type="checkbox" />
                              Refrigerated Cargo
                            </label>
                          </span>
                          <span class="checkbox_item">
                            <label>
                              <input type="checkbox" />
                              Live Animals
                            </label>
                          </span>
                        </span>
                      </p>

                      <p class="field check-box">
                        {/* <label>
                          Freight Type<span class="require">*</span>
                        </label> */}
                        <span class="checkbox-box">
                          <span class="checkbox_item">
                            <label>
                              <input type="checkbox" />
                              High Frequency
                            </label>
                          </span>
                          <span class="checkbox_item">
                            <label>
                              <input type="checkbox" />
                              Cantabrian Seaboard
                            </label>
                          </span>
                          <span class="checkbox_item">
                            <label>
                              <input type="checkbox" />
                              Atlantic Seaboard
                            </label>
                          </span>
                          <span class="checkbox_item">
                            <label>
                              <input type="checkbox" />
                              Mediterranean Seaboard
                            </label>
                          </span>
                        </span>
                      </p>

                      <p class="field check-box">
                        <label>
                          Resultados Orderados Por<span class="require">*</span>
                        </label>
                        <span class="checkbox-box">
                          <span class="checkbox_item">
                            <label>
                              <input type="radio" />
                              Coste
                            </label>
                          </span>
                          <span class="checkbox_item">
                            <label>
                              <input type="radio" />
                              Tiempo
                            </label>
                          </span>
                          <span class="checkbox_item">
                            <label>
                              <input type="radio" />
                              Distanceiad
                            </label>
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {console.log(Center, "center")}
          {map ? (
            <>
              {/* {mapLoaded ? ( */}
              <GoogleMap
                mapContainerStyle={mapStyles}
                center={Center}
                zoom={4}
                apiKey="AIzaSyB0FjvCBybYldOrdRoI6GkvurRvN5uF1PI"
              >
                {polylinePaths.map((path, index) => {
                  const label = labels[index % labels.length];
                  const startPoint = path[0];
                  return (
                    <React.Fragment key={index}>
                      <PolylineF
                        key={index}
                        path={path}
                        options={{
                          strokeColor: "#FF0000",
                          strokeOpacity: 1.0,
                          strokeWeight: 2,
                        }}
                      />
                      <Marker position={startPoint} label={label} />
                    </React.Fragment>
                  );
                })}

                {directionsService &&
                  directionsService.map((response, index) => (
                    // console.log(response, "alllegs:");

                    <DirectionsRenderer key={index} directions={response} />
                  ))}
              </GoogleMap>
              {/* ) : (
            <div>Loading map...</div>
          )} */}
            </>
          ) : null}

          {railmap ? (
            <>
              {/* {mapLoaded ? ( */}
              <GoogleMap
                mapContainerStyle={mapStyles}
                center={Center}
                zoom={4}
                apiKey="AIzaSyB0FjvCBybYldOrdRoI6GkvurRvN5uF1PI"
              >
                {polylinePaths.map((path, index) => {
                  // const label = labels[index % labels.length];
                  // const startPoint = path[0];
                  return (
                    <React.Fragment key={index}>
                      <PolylineF
                        key={index}
                        path={path}
                        options={{
                          strokeColor: "#FF0000",
                          strokeOpacity: 1.0,
                          strokeWeight: 2,
                        }}
                      />
                      <MarkerF position={path} label={labels} />
                    </React.Fragment>
                  );
                })}
                {/* <MarkerF position={Center} /> */}
                {directionsService &&
                  directionsService.map((response, index) => (
                    <DirectionsRenderer key={index} directions={response} />
                  ))}
              </GoogleMap>
              {/* ) : (
            <div>Loading map...</div>
          )} */}
            </>
          ) : null}

          {table ? (
            <>
              <h4 className="text-red">
                Transporte por carretera <span>/Transport by 'only road'</span>
              </h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan={2}>Origen-Destino/From-To</th>
                    <th>Cost(Eur) </th>
                    <th>Tiempo (Hor) </th>
                    <th>Dist. (Km) </th>
                    <th> CO2(Kg)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <>
                        <div className="d-flex justify-content-between align-items-center px-5">
                          <p>
                            {" "}
                            {Origin_des.length > 0 &&
                              Origin_des[0].start_address}
                          </p>
                          <p>=========</p>
                          <p>
                            {Origin_des.length > 0 && Origin_des[0].end_address}
                          </p>
                        </div>
                      </>
                    </td>

                    <td>
                      {Origin_des.length > 0 &&
                        Number(euro) * Number(singleCalculateCost)}
                    </td>

                    <td>
                      {Origin_des.length > 0 && Origin_des[0]?.duration.text}
                    </td>
                    <td>
                      {Origin_des.length > 0 && Origin_des[0]?.distance.text}
                    </td>

                    <td>
                      {Origin_des.length > 0 &&
                        Math.round(
                          Origin_des[0].distance.value *
                            (0.9574468086).toFixed(3)
                        )}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h4 className="text-red">
                Transporte Intermodal con líneas marítimas existentes.
              </h4>
              <Accordion defaultActiveKey={["0"]} alwaysOpen>
                {SelectBox === "all routes" ? (
                  <>
                    {directionsService?.map((e, i) => (
                      <>
                        <Accordion.Item eventKey={i}>
                          <Accordion.Header>
                            <div className="w-100">
                              <div className="d-flex justify-content-around align-items-center">
                                <p className="text-center">
                                  <span className="text-red">
                                    Origen-Destino/From-To
                                  </span>
                                  <br />
                                  <br />
                                  {e[1]?.request?.origin?.query}
                                  &nbsp;&nbsp;=========&nbsp;&nbsp;
                                  {e[1]?.request?.destination?.query}
                                </p>
                                <p className="text-center">
                                  <span className="text-red"> Cost(Eur)</span>
                                  <br />
                                  <br />
                                  {e[1]?.routes?.length >= 0 &&
                                    Math.round(
                                      Number(euro) *
                                        e[1]?.routes[0]?.legs[0]?.distance.value
                                    )}
                                  {/* 23456 */}
                                </p>
                                <p className="text-center">
                                  <span className="text-red">
                                    {" "}
                                    Tiempo (Hor)
                                  </span>
                                  <br />
                                  <br />
                                  {e[1]?.routes?.length >= 0 &&
                                    e[1]?.routes[0]?.legs[0]?.duration.text}
                                </p>
                                <p className="text-center">
                                  <span className="text-red"> Dist. (Km)</span>
                                  <br />
                                  <br />
                                  {e[1]?.routes?.length >= 0 &&
                                    e[1]?.routes[0]?.legs[0]?.distance.text}
                                </p>
                                <p className="text-center">
                                  <span className="text-red">
                                    {" "}
                                    Cost Ext.(Eur) Emis.
                                  </span>
                                  <br />
                                  <br />
                                  23456
                                </p>
                                <p className="text-center">
                                  <span className="text-red">CO2(Kg)</span>
                                  <br />
                                  <br />
                                  23456
                                </p>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th colSpan={2}>Origen-Destino/From-To</th>
                                  <th>Cost(Eur) </th>
                                  <th>Tiempo (Hor) </th>
                                  <th>Dist. (Km) </th>
                                  <th>Cost Ext.(Eur) Emis</th>
                                  <th> CO2(Kg)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {e?.map((i) => (
                                  <tr>
                                    <td colSpan={2}>
                                      <div className="d-flex justify-content-between align-items-center px-5">
                                        <p>{i?.request?.origin?.query}</p>
                                        <p>=========</p>
                                        <p>{i?.request?.destination?.query}</p>
                                      </div>
                                    </td>

                                    <td>
                                      {i?.routes?.length >= 0 &&
                                        Math.round(
                                          Number(euro) *
                                            i?.routes[0]?.legs?.[0]?.distance
                                              .value
                                        )}
                                    </td>

                                    <td>
                                      {i?.routes?.length >= 0 &&
                                        i?.routes[0]?.legs?.[0]?.duration.text}
                                    </td>
                                    <td>
                                      {i?.routes?.length >= 0 &&
                                        i?.routes[0]?.legs[0]?.distance.text}
                                    </td>

                                    <td>@mdo</td>
                                    <td>@mdo</td>
                                  </tr>
                                ))}
                                {/* <tr>
                                <td colSpan={2}>
                                  <div className="d-flex justify-content-between align-items-center px-5">
                                    <p>
                                      {datas.length > 0 && datas[1]?.origin}
                                    </p>
                                    <p>=========</p>
                                    <p>
                                      {datas.length > 0 &&
                                        datas[1]?.destination}
                                    </p>
                                  </div>
                                </td>

                                <td>Otto</td>

                                <td>
                                  {Distance_duration?.length > 0 &&
                                    Distance_duration[1][0].duration.text}
                                </td>
                                <td>
                                  {Distance_duration?.length > 0 &&
                                    Distance_duration[1][0].distance.text}
                                </td>

                                <td>@mdo</td>
                                <td>@mdo</td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <div className="d-flex justify-content-between align-items-center px-5">
                                    <p>{datas[2]?.origin}</p>
                                    <p>=========</p>
                                    <p>{datas[2]?.destination}</p>
                                  </div>
                                </td>

                                <td>Otto</td>

                                <td>
                                  {Distance_duration?.length > 0 &&
                                    Distance_duration[2][0].duration.text}
                                </td>
                                <td>
                                  {Distance_duration?.length > 0 &&
                                    Distance_duration[2][0].distance.text}
                                </td>

                                <td>@mdo</td>
                                <td>@mdo</td>
                              </tr> */}
                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    {Routes?.filter((e, i) => e.value === Selectvalue).map(
                      (e, i) => (
                        <>
                          <Accordion.Item eventKey={i}>
                            <Accordion.Header>
                              <div className="w-100">
                                <div className="d-flex justify-content-around align-items-center">
                                  <p className="text-center">
                                    <span className="text-red">
                                      Origen-Destino/From-To
                                    </span>
                                    <br />
                                    <br />
                                    {datas[1]?.origin} ===
                                    {datas[1]?.destination}
                                  </p>
                                  <p className="text-center">
                                    <span className="text-red"> Cost(Eur)</span>
                                    <br />
                                    <br />
                                    {Distance_duration &&
                                      Distance_duration.length >= 2 &&
                                      Distance_duration[1].routes &&
                                      Distance_duration[1].routes.length >= 0 &&
                                      Math.round(
                                        Number(euro) *
                                          Number(
                                            Distance_duration &&
                                              Distance_duration.length >= 2 &&
                                              Distance_duration[1].routes &&
                                              Distance_duration[1].routes
                                                .length >= 0 &&
                                              Distance_duration[1].routes[0]
                                                .legs[0].distance.value
                                          )
                                      )}
                                  </p>
                                  <p className="text-center">
                                    <span className="text-red">
                                      {" "}
                                      Tiempo (Hor)
                                    </span>
                                    <br />
                                    <br />
                                    {Distance_duration &&
                                      Distance_duration.length >= 2 &&
                                      Distance_duration[1].routes &&
                                      Distance_duration[1].routes.length >= 0 &&
                                      Distance_duration[1].routes[0].legs[0]
                                        .duration.text}
                                  </p>
                                  <p className="text-center">
                                    <span className="text-red">
                                      {" "}
                                      Dist. (Km)
                                    </span>
                                    <br />
                                    <br />
                                    {Distance_duration &&
                                      Distance_duration.length >= 2 &&
                                      Distance_duration[1].routes &&
                                      Distance_duration[1].routes.length >= 0 &&
                                      Distance_duration[1].routes[0].legs[0]
                                        .distance.text}
                                  </p>
                                  {/* <p className="text-center">
                                <span className="text-red">
                                  {" "}
                                  Cost Ext.(Eur) Emis.
                                </span>
                                <br />
                                <br />
                                23456
                              </p> */}
                                  <p className="text-center">
                                    <span className="text-red">CO2(Kg)</span>
                                    <br />
                                    <br />
                                    {Math.round(
                                      Number(
                                        Distance_duration &&
                                          Distance_duration.length >= 2 &&
                                          Distance_duration[1].routes &&
                                          Distance_duration[1].routes.length >=
                                            0 &&
                                          Distance_duration[1].routes[0].legs[0]
                                            .distance.value
                                      ) * (0.9574468086).toFixed(3)
                                    )}
                                  </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th colSpan={2}>Origen-Destino/From-To</th>
                                    <th>Cost(Eur) </th>
                                    <th>Tiempo (Hor) </th>
                                    <th>Dist. (Km) </th>
                                    {/* <th>Cost Ext.(Eur) Emis</th> */}
                                    <th> CO2(Kg)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td colSpan={2}>
                                      <div className="d-flex justify-content-between align-items-center px-5">
                                        <p>
                                          {datas?.length > 0 &&
                                            datas[0]?.origin}
                                        </p>
                                        <p>=========</p>
                                        <p>
                                          {datas?.length > 0 &&
                                            datas[0]?.destination}
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      {CalculateCost &&
                                        CalculateCost.length >= 2 &&
                                        CalculateCost[0].routes &&
                                        CalculateCost[0].routes.length >= 0 &&
                                        Number(euro) *
                                          Number(
                                            CalculateCost &&
                                              CalculateCost.length >= 2 &&
                                              CalculateCost[0].routes &&
                                              CalculateCost[0].routes.length >=
                                                0 &&
                                              CalculateCost[0].routes[0].legs[0]
                                                .distance.value
                                          )}
                                    </td>

                                    <td>
                                      {Distance_duration &&
                                        Distance_duration.length >= 2 &&
                                        Distance_duration[0].routes &&
                                        Distance_duration[0].routes.length >=
                                          0 &&
                                        Distance_duration[0]?.routes[0]?.legs[0]
                                          ?.duration.text}
                                    </td>
                                    <td>
                                      {Distance_duration &&
                                        Distance_duration.length >= 2 &&
                                        Distance_duration[0].routes &&
                                        Distance_duration[0].routes.length >=
                                          0 &&
                                        Distance_duration[0].routes[0].legs[0]
                                          .distance.text}
                                    </td>

                                    {/* <td>@mdo</td> */}
                                    <td>
                                      {Math.round(
                                        Number(
                                          Distance_duration &&
                                            Distance_duration.length >= 2 &&
                                            Distance_duration[0].routes &&
                                            Distance_duration[0].routes
                                              .length >= 0 &&
                                            Distance_duration[0].routes[0]
                                              .legs[0].distance.value
                                        ) * (0.9574468086).toFixed(3)
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={2}>
                                      <div className="d-flex justify-content-between align-items-center px-5">
                                        <p>
                                          {datas.length > 0 && datas[1]?.origin}
                                        </p>
                                        <p>=========</p>
                                        <p>
                                          {datas.length > 0 &&
                                            datas[1]?.destination}
                                        </p>
                                      </div>
                                    </td>

                                    <td> {e.cost}</td>
                                    <td>{e.time}</td>
                                    <td>{e.distance}</td>

                                    {/* <td>@mdo</td> */}
                                    <td>{e.co2}</td>
                                  </tr>
                                  <tr>
                                    <td colSpan={2}>
                                      <div className="d-flex justify-content-between align-items-center px-5">
                                        <p>{datas[2]?.origin}</p>
                                        <p>=========</p>
                                        <p>{datas[2]?.destination}</p>
                                      </div>
                                    </td>

                                    <td>
                                      {CalculateCost &&
                                        CalculateCost.length >= 2 &&
                                        CalculateCost[2].routes &&
                                        CalculateCost[2].routes.length >= 0 &&
                                        Number(euro) *
                                          Number(
                                            CalculateCost &&
                                              CalculateCost.length >= 2 &&
                                              CalculateCost[2].routes &&
                                              CalculateCost[2].routes.length >=
                                                0 &&
                                              CalculateCost[2].routes[0].legs[0]
                                                .distance.value
                                          )}
                                    </td>

                                    <td>
                                      {Distance_duration &&
                                        Distance_duration.length >= 0 &&
                                        Distance_duration[2].routes &&
                                        Distance_duration[2].routes.length >=
                                          0 &&
                                        Distance_duration[2].routes[0].legs[0]
                                          .duration.text}
                                    </td>
                                    <td>
                                      {Distance_duration &&
                                        Distance_duration.length >= 0 &&
                                        Distance_duration[2].routes &&
                                        Distance_duration[2].routes.length >=
                                          0 &&
                                        Distance_duration[2].routes[0].legs[0]
                                          .distance.text}
                                    </td>

                                    {/* <td>@mdo</td> */}
                                    <td>
                                      {Math.round(
                                        Number(
                                          Distance_duration &&
                                            Distance_duration.length >= 2 &&
                                            Distance_duration[2].routes &&
                                            Distance_duration[2].routes
                                              .length >= 0 &&
                                            Distance_duration[2].routes[0]
                                              .legs[0].distance.value
                                        ) * (0.9574468086).toFixed(3)
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Accordion.Body>
                          </Accordion.Item>
                        </>
                      )
                    )}
                  </>
                )}
              </Accordion>
            </>
          ) : null}

          {rail ? (
            <>
              <h4 className="text-red">
                Transporte por carretera <span>/Transport by 'only road'</span>
              </h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan={2}>Origen-Destino/From-To</th>
                    <th>Cost(Eur) </th>
                    <th>Tiempo (Hor) </th>
                    <th>Dist. (Km) </th>
                    <th> CO2(Kg)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <>
                        <div className="d-flex justify-content-between align-items-center px-5">
                          <p>
                            {" "}
                            {Origin_des.length > 0 &&
                              Origin_des[0].start_address}
                          </p>
                          <p>=========</p>
                          <p>
                            {Origin_des.length > 0 && Origin_des[0].end_address}
                          </p>
                        </div>
                      </>
                    </td>

                    <td>
                      {/* {console.log(Number(raileuro) ,Number(Origin_des[0].distance.text.split(" ")[0]),"alllegs:")} */}
                      {Origin_des.length > 0 &&
                        Number(raileuro) * Number(singleCalculateCost)}
                    </td>

                    <td>
                      {Origin_des.length > 0 && Origin_des[0]?.duration.text}
                    </td>
                    <td>
                      {Origin_des.length > 0 && Origin_des[0]?.distance.text}
                    </td>

                    <td>
                      {Origin_des.length > 0 &&
                        Math.round(Origin_des[0].distance.value) *
                          (0.9574468086).toFixed(3)}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h4 className="text-red">
                Transporte Intermodal con líneas marítimas existentes.
              </h4>
              <Accordion defaultActiveKey={["0"]} alwaysOpen>
                <>
                  {RailywayRoutes?.filter((e, i) => e.label == SelectBox).map(
                    (e, i) => (
                      <>
                        {/* {console.log(e, "cnknscscn")} */}
                        <Accordion.Item eventKey={i}>
                          <Accordion.Header>
                            <div className="w-100">
                              <div className="d-flex justify-content-around align-items-center">
                                <p className="text-center">
                                  <span className="text-red">
                                    Origen-Destino/From-To
                                  </span>
                                  <br />
                                  <br />
                                  {datas[1]?.origin} ==={datas[1]?.destination}
                                </p>
                                <p className="text-center">
                                  <span className="text-red"> Cost(Eur)</span>
                                  <br />
                                  <br />
                                  {e?.cost}
                                </p>
                                <p className="text-center">
                                  <span className="text-red">
                                    {" "}
                                    Tiempo (Hor)
                                  </span>
                                  <br />
                                  <br />
                                  {Distance_duration &&
                                    Distance_duration.length >= 2 &&
                                    Distance_duration[1].routes &&
                                    Distance_duration[1].routes.length >= 0 &&
                                    Distance_duration[1].routes[0].legs[0]
                                      .duration.text}
                                </p>
                                <p className="text-center">
                                  <span className="text-red"> Dist. (Km)</span>
                                  <br />
                                  <br />
                                  {e?.distance}
                                </p>
                                {/* <p className="text-center">
                                <span className="text-red">
                                  {" "}
                                  Cost Ext.(Eur) Emis.
                                </span>
                                <br />
                                <br />
                                23456
                              </p> */}
                                <p className="text-center">
                                  <span className="text-red">CO2(Kg)</span>
                                  <br />
                                  <br />
                                  {e?.co2}
                                </p>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th colSpan={2}>Origen-Destino/From-To</th>
                                  <th>Cost(Eur) </th>
                                  <th>Tiempo (Hor) </th>
                                  <th>Dist. (Km) </th>
                                  {/* <th>Cost Ext.(Eur) Emis</th> */}
                                  <th> CO2(Kg)</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td colSpan={2}>
                                    <div className="d-flex justify-content-between align-items-center px-5">
                                      <p>
                                        {datas?.length > 0 && datas[0]?.origin}
                                      </p>
                                      <p>=========</p>
                                      <p>
                                        {datas?.length > 0 &&
                                          datas[0]?.destination}
                                      </p>
                                    </div>
                                  </td>

                                  <td>
                                    {CalculateCost &&
                                      CalculateCost.length >= 2 &&
                                      CalculateCost[0].routes &&
                                      CalculateCost[0].routes.length >= 0 &&
                                      Number(raileuro) *
                                        Number(
                                          CalculateCost &&
                                            CalculateCost.length >= 2 &&
                                            CalculateCost[0].routes &&
                                            CalculateCost[0].routes.length >=
                                              0 &&
                                            CalculateCost[0].routes[0].legs[0]
                                              .distance.value
                                        )}
                                  </td>

                                  <td>
                                    {Distance_duration &&
                                      Distance_duration.length >= 2 &&
                                      Distance_duration[2].routes &&
                                      Distance_duration[2].routes.length >= 0 &&
                                      Distance_duration[2]?.routes[0]?.legs[0]
                                        ?.duration.text}
                                  </td>
                                  <td>
                                    {Distance_duration &&
                                      Distance_duration.length >= 2 &&
                                      Distance_duration[2].routes &&
                                      Distance_duration[2].routes.length >= 0 &&
                                      Distance_duration[2].routes[0].legs[0]
                                        .distance.text}
                                  </td>

                                  {/* <td>@mdo</td> */}
                                  <td>
                                    {Math.round(
                                      Number(
                                        Distance_duration &&
                                          Distance_duration.length >= 2 &&
                                          Distance_duration[2].routes &&
                                          Distance_duration[2].routes.length >=
                                            0 &&
                                          Distance_duration[2].routes[0].legs[0]
                                            .distance.value
                                      ) * (0.9574468086).toFixed(3)
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={2}>
                                    <div className="d-flex justify-content-between align-items-center px-5">
                                      <p>
                                        {datas.length > 0 && datas[1]?.origin}
                                      </p>
                                      <p>=========</p>
                                      <p>
                                        {datas.length > 0 &&
                                          datas[1]?.destination}
                                      </p>
                                    </div>
                                  </td>

                                  <td> {e.cost}</td>
                                  <td>{e.time}</td>
                                  <td>{e.distance}</td>

                                  {/* <td>@mdo</td> */}
                                  <td>{e.co2}</td>
                                </tr>
                                <tr>
                                  <td colSpan={2}>
                                    <div className="d-flex justify-content-between align-items-center px-5">
                                      <p>{datas[2]?.origin}</p>
                                      <p>=========</p>
                                      <p>{datas[2]?.destination}</p>
                                    </div>
                                  </td>

                                  <td>
                                    {CalculateCost &&
                                      CalculateCost.length >= 2 &&
                                      CalculateCost[2].routes &&
                                      CalculateCost[2].routes.length >= 0 &&
                                      Number(RaildestEuro) *
                                        Number(
                                          CalculateCost &&
                                            CalculateCost.length >= 2 &&
                                            CalculateCost[2].routes &&
                                            CalculateCost[2].routes.length >=
                                              0 &&
                                            CalculateCost[2].routes[0].legs[0]
                                              .distance.value
                                        )}
                                  </td>
                                  <td>
                                    {Distance_duration &&
                                      Distance_duration.length >= 0 &&
                                      Distance_duration[0].routes &&
                                      Distance_duration[0].routes.length >= 0 &&
                                      Distance_duration[0].routes[0].legs[0]
                                        .duration.text}
                                  </td>
                                  <td>
                                    {Distance_duration &&
                                      Distance_duration.length >= 0 &&
                                      Distance_duration[0].routes &&
                                      Distance_duration[0].routes.length >= 0 &&
                                      Distance_duration[0].routes[0].legs[0]
                                        .distance.text}
                                  </td>
                                  {/* <td>@mdo</td> */}
                                  <td>
                                    {Math.round(
                                      Number(
                                        Distance_duration &&
                                          Distance_duration.length >= 2 &&
                                          Distance_duration[0].routes &&
                                          Distance_duration[0].routes.length >=
                                            0 &&
                                          Distance_duration[0].routes[0].legs[0]
                                            .distance.value
                                      ) * (0.9574468086).toFixed(3)
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      </>
                    )
                  )}
                </>
              </Accordion>
            </>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default RequestToQuote;
