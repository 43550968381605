import React from 'react'

const SectionHeader = (props) => {
    const {head}=props
  return (
    <div>
      <div class="main header">
  <span>{head}</span>
</div>
    </div>
  )
}

export default SectionHeader
