import React from 'react'
import Pagetitle from '../Component/Pagetitle'
import ContactDetails from '../Component/Contact/ContactDetails'
import Message from '../Component/Contact/Message'
// import Map from '../Component/Contact/Map'

const Contact = () => {
  return (
    <>
    <Pagetitle title="Conatct Us"/>
    <ContactDetails/>
    <Message/>
    {/* <Map/> */}
    </>
  )
}

export default Contact